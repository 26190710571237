import React, { useEffect, useState } from "react";
import { LngLatBounds, ViewState } from "react-map-gl";

import SightseeingSpotMarkers from "../../components/map/SightseeingSpotMarkers";
import MapSearchForSelectedPlaceButton from "../../components/buttons/MapSearchForSelectedPlaceButton";
import useSightseeingSpotsByCoordinates from "../../hooks/useSightseeingSpotsByCoordinates";
import { useLocale } from "../../contexts/LocaleContext";
import CircleLayer from "../../components/map/CircleLayer";
import { getDistanceBetweenCoordinates } from "../../helpers/turf-helpers";
import { useCity } from "../../contexts/CityContext";

const SightseeingSpotMarkersWithSearchArea: React.FC<{
  viewState: ViewState;
  mapBounds?: LngLatBounds | null;
  queryRadius: number;  
  buttonClassName?: string;
}> = ({ viewState, mapBounds, queryRadius, buttonClassName }) => {
  const { locale } = useLocale();
  const { currentCity } = useCity();

  const [
    isSearchInPlaceFunctionalityVisible,
    setIsSearchInPlaceFunctionalityVisible,
  ] = useState(false);
  const [queryViewState, setQueryViewState] = useState<ViewState>(viewState);

  const { sightseeingSpotsInQueryLocale, resetSightseeingSpots } =
    useSightseeingSpotsByCoordinates(true, queryRadius, queryViewState);

  useEffect(() => {
    const isSearchInPlaceFunctionalityVisible =
      (getDistanceBetweenCoordinates(queryViewState, viewState) || 0) >
      queryRadius;
    setIsSearchInPlaceFunctionalityVisible(isSearchInPlaceFunctionalityVisible);
  }, [queryViewState, viewState, queryRadius]);

  useEffect(
    () => {
      if (currentCity?.location) {
        setQueryViewState({
          ...viewState,
          latitude: currentCity.location.latitude,
          longitude: currentCity.location.longitude,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCity]
  );

  return (
    <>
      {!!sightseeingSpotsInQueryLocale?.[locale]?.length && (
        <SightseeingSpotMarkers
          sightSeeingSpots={sightseeingSpotsInQueryLocale?.[locale]}
          zoom={viewState.zoom}
        />
      )}

      <CircleLayer
        viewState={queryViewState}
        hideCircleIfMapIsFullyInside={true}
        mapBounds={mapBounds}
        radius={queryRadius}
        type="fill"
        paint={{
          "fill-color": "#e38873",
          "fill-opacity": 0.3,
        }}
      />

      {isSearchInPlaceFunctionalityVisible && (
        <CircleLayer
          viewState={viewState}
          hideCircleIfMapIsFullyInside={false}
          radius={queryRadius}
          type="line"
          paint={{
            "line-color": "#e38873",
            "line-opacity": 0.5,
            "line-width": 3,
          }}
        />
      )}

      {isSearchInPlaceFunctionalityVisible && (
        <MapSearchForSelectedPlaceButton
          buttonRef={null}
          onClick={() => {
            setQueryViewState(viewState);
            resetSightseeingSpots();
          }}
          className={buttonClassName}
        />
      )}
    </>
  );
};

export default SightseeingSpotMarkersWithSearchArea;
