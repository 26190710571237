import { useCallback, useEffect, useState } from "react";
import { IonButton, IonCard, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { take } from "lodash-es";
import { Image } from "react-datocms";
import { arrowForwardOutline } from "ionicons/icons";

import SectionHeader from "./SectionHeader";
import {
  LatLonField,
  usePopularSightseeingSpotsByCityIdQuery,
} from "../../../graphql/dato/__generated__/dato-graphql.generated";
import { City, SightseeingSpot } from "../../../interfaces/Interfaces";
import { useLocale } from "../../../contexts/LocaleContext";
import useRoutes from "../../../hooks/useRoutes";
import PlaceSearchImage from "../../../components/media/PlaceSearchImage";

const PopularSightseeingSpotsSection: React.FC<{
  city: City;
  isVisible: boolean;
}> = ({ city, isVisible }) => {
  const { queryLocale } = useLocale();
  const { t } = useTranslation();
  const { localePath } = useRoutes();

  const [showAllInQueryLocale, setShowAllInQueryLocale] = useState<{
    [key: string]: boolean;
  }>({});

  const [sightseeingSpots, setSightseeingSpots] = useState<SightseeingSpot[]>(
    []
  );

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      cityId: city?.id,
      first: 100,
      skip: 0,
    };
  }, [queryLocale, city]);

  const [result] = usePopularSightseeingSpotsByCityIdQuery({
    variables: queryVariables(),
    pause: !isVisible,
  });

  const { data } = result;

  useEffect(
    () => {
      if (data?.sightseeingSpots) {
        setSightseeingSpots(data.sightseeingSpots as SightseeingSpot[]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(() => {
    setShowAllInQueryLocale({});
  }, [city]);

  if (!sightseeingSpots?.length) return null;

  return (
    <div>
      <div className="mb-0.5 mt-4 flex w-full items-center justify-between pl-4 pr-0">
        <SectionHeader
          title={t("home.sightseeingSpots.title", { city: city?.name })}
        />
        <IonButton
          fill="clear"
          className="normal-case tracking-normal text-primary"
          onClick={() =>
            setShowAllInQueryLocale({
              ...showAllInQueryLocale,
              [queryLocale]: !showAllInQueryLocale[queryLocale],
            })
          }
        >
          <span className="font-semibold">
            {t(
              showAllInQueryLocale[queryLocale]
                ? "home.sightseeingSpots.buttons.showLess"
                : "home.sightseeingSpots.buttons.showMore"
            )}
          </span>
        </IonButton>
      </div>
      <div className="mx-3 pt-1">
        {(showAllInQueryLocale[queryLocale]
          ? sightseeingSpots
          : take(sightseeingSpots, 4)
        )?.map((sightseeingSpot) => (
          <IonCard
            key={sightseeingSpot.id}
            className="relative mx-0 my-3 aspect-[2/1] rounded-[13px]"
            routerLink={localePath(`sightseeing-spots/${sightseeingSpot.id}`)}
            routerDirection="forward"
          >
            {!!sightseeingSpot?.preview?.[0]?.image?.responsiveImage ? (
              <Image
                data={sightseeingSpot?.preview?.[0]?.image?.responsiveImage}
                layout="fill"
                objectFit="cover"
              />
            ) : (
              <PlaceSearchImage
                searchText={sightseeingSpot?.name as string}
                location={sightseeingSpot?.location as LatLonField}
                maxWidth={576}
                className="absolute"
              />
            )}

            <div
              className="absolute bottom-0 left-0 right-0 top-0"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.68) 87.50%)",
              }}
            />
            <div className="absolute bottom-4 flex w-full items-center justify-between px-4 text-white">
              <div className="text-center text-[1.125rem] font-medium">
                {sightseeingSpot.name}
              </div>
              <IonIcon
                slot="end"
                icon={arrowForwardOutline}
                className="text-[1.25rem]"
              />
            </div>
          </IonCard>
        ))}
      </div>
    </div>
  );
};

export default PopularSightseeingSpotsSection;
