import { IonContent, IonProgressBar, useIonViewDidEnter } from "@ionic/react";
import { find, groupBy, keys, map, reduce } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as moment from "moment";

import AppLayout from "../layouts/AppLayout";
import FloatingBackButton from "../components/buttons/FloatingBackButton";
import useError from "../hooks/useError";

import { useLocale } from "../contexts/LocaleContext";
import { Tour } from "../interfaces/Interfaces";
import { useToursByTourIdsQuery } from "../graphql/dato/__generated__/dato-graphql.generated";
import {
  TourCreationRequest,
  TourCreationRequestStatus,
  useGetTourCreationRequestsByUserLazyQuery,
} from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import useIonVisible from "../hooks/useIonVisible";
import useAuthStore from "../stores/useAuthStore";
import FullWidthTourCard from "../components/cards/FullWidthTourCard";
import { useTourCreationRequest } from "../contexts/TourCreationRequestContext";
import useToursRatingStatistics from "../hooks/useToursRatingStatistics";
import useUserToursAudioStatistics from "../hooks/useUserToursAudioStatistics";

const CreatedToursPage: React.FC = () => {
  const { handleBackendError } = useError();
  const { queryLocale } = useLocale();
  const { t } = useTranslation();
  const { isVisible } = useIonVisible();
  const { tourCreationRequestStatisticsByUser } = useTourCreationRequest();

  const [isLoadingRequests, setIsLoadingRequests] = useState(true);

  const [getTourCreationRequestsByUserQuery] =
    useGetTourCreationRequestsByUserLazyQuery();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [successfulTourCreationRequests, setSuccessfulTourCreationRequests] =
    useState<Partial<TourCreationRequest>[]>([]);
  const [tourIds, setTourIds] = useState<string[]>([]);
  const [tours, setTours] = useState<Tour[]>([]);
  const [groupedToursByDate, setGroupedToursByDate] = useState<
    Array<{ createdAt: string; tours: Tour[] }>
  >([]);

  const { toursRatingStatistics } = useToursRatingStatistics(
    map(tours, ({ id }) => id),
    isVisible
  );
  const { userToursAudioStatistics } = useUserToursAudioStatistics(
    map(tours, ({ id }) => id),
    isAuthenticated,
    isVisible
  );

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      tourIds,
      first: 100,
      skip: 0,
    };
  }, [tourIds, queryLocale]);

  const [toursResult] = useToursByTourIdsQuery({
    variables: queryVariables(),
    pause: !tourIds?.length,
  });

  useIonViewDidEnter(() => {
    const getTourCreationRequestsByUser = async () => {
      setIsLoadingRequests(true);
      await handleBackendError(async () => {
        const { data, error } = await getTourCreationRequestsByUserQuery({
          variables: {
            input: { statuses: [TourCreationRequestStatus.Success] },
          },
          fetchPolicy: "no-cache",
        });

        if (error) return error;

        const successfulRequests =
          data?.tourCreationRequest?.getTourCreationRequestsByUser || [];

        setSuccessfulTourCreationRequests(successfulRequests);
      });
      setIsLoadingRequests(false);
    };

    getTourCreationRequestsByUser();
  });

  useEffect(() => {
    const tourIds = reduce(
      successfulTourCreationRequests,
      (ids: string[], successRequest) => {
        return successRequest.datoTourId
          ? [...ids, successRequest.datoTourId]
          : ids;
      },
      []
    );

    setTourIds(tourIds);
  }, [successfulTourCreationRequests]);

  useEffect(() => {
    const tours = toursResult?.data?.tours as Tour[];
    setTours(tours);
  }, [toursResult]);

  useEffect(() => {
    if (!tours?.length || !successfulTourCreationRequests?.length) {
      setGroupedToursByDate([]);
      return;
    }

    const requestsWithTours = reduce(
      successfulTourCreationRequests,
      (acc: any, request) => {
        const tour = find(tours, ["id", request.datoTourId]);
        if (tour)
          acc.push({
            ...request,
            tour,
            createdAt: moment(request.createdAt).format("DD.MM.yyyy"),
          });
        return acc;
      },
      []
    );

    const grouppedRequestsByDate = groupBy(requestsWithTours, "createdAt");
    const creationDates = keys(grouppedRequestsByDate);
    const grouppedToursByDate = map(creationDates, (createdAt) => ({
      createdAt,
      tours: map(grouppedRequestsByDate[createdAt], (request) => request.tour),
    }));
    setGroupedToursByDate(grouppedToursByDate);
  }, [tours, successfulTourCreationRequests]);

  return (
    <AppLayout isLoaderShown={isLoadingRequests || toursResult.fetching}>
      <IonContent
        style={{
          "--padding-top": "var(--ion-safe-area-top)",
        }}
      >
        <div className="relative mx-auto max-w-md">
          <FloatingBackButton style={{ left: "6px" }} />

          {!isLoadingRequests && !toursResult.fetching && (
            <h3 className="ml-1 pb-2 pl-3 pt-[75px] text-[1.1rem] font-semibold text-[#414254]">
              {t(
                tours?.length
                  ? "profile.createdTours.title"
                  : "profile.createdTours.doNotHaveCreatedTours"
              )}
            </h3>
          )}

          {!!tourCreationRequestStatisticsByUser?.inProgress && (
            <>
              <IonProgressBar type="indeterminate" />
              <div className="bg-[#A4D5CD] px-4 py-2 text-[0.875rem] font-medium text-white">
                {t("profile.createdTours.tourCreationInProgress")}
              </div>
            </>
          )}

          {map(groupedToursByDate, (group) => {
            return (
              <div key={group?.createdAt}>
                <p className="mb-2 bg-primary py-1 text-center text-[0.875rem] font-bold text-white">
                  {group.createdAt}
                </p>
                {map(group.tours, (tour) => {
                  return (
                    <div key={tour.id}>
                      <FullWidthTourCard
                        tour={tour}
                        tourRatingStatistics={find(toursRatingStatistics, [
                          "datoTourId",
                          tour.id,
                        ])}
                        userTourAudioStatistics={find(
                          userToursAudioStatistics,
                          ["datoTourId", tour.id]
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CreatedToursPage;
