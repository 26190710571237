import { useCallback, useEffect, useState } from "react";
import { uniqBy } from "lodash-es";

import { useLocale } from "../contexts/LocaleContext";
import { useExploreSightseeingSpotsByCoordinatesQuery } from "../graphql/dato/__generated__/dato-graphql.generated";
import { SightseeingSpot } from "../interfaces/Interfaces";

const useSightseeingSpotsByCoordinates = (
  isVisible: boolean,
  queryRadius: number, // radius in meters
  centerCoordinates: { latitude: number; longitude: number }
) => {
  const { queryLocale, locale } = useLocale();

  const [sightseeingSpotsInQueryLocale, setSightseeingSpotsInQueryLocale] =
    useState<{
      [key: string]: SightseeingSpot[];
    }>({});

  // Query for sightseeing spots when rending the component
  const sightseeingSpotsPageSize = 100;
  const [sightseeingSpotsPageNumber, setSightseeingSpotsPageNumber] =
    useState(0);

  const sightseeingSpotsQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      latitude: centerCoordinates.latitude,
      longitude: centerCoordinates.longitude,
      radius: queryRadius, // radius in meters
      first: sightseeingSpotsPageSize,
      skip: sightseeingSpotsPageNumber * sightseeingSpotsPageSize,
    };
  }, [queryLocale, queryRadius, centerCoordinates, sightseeingSpotsPageNumber]);

  const [sightseeingSpotsResult] = useExploreSightseeingSpotsByCoordinatesQuery(
    {
      variables: sightseeingSpotsQueryVariables(),
      pause: !isVisible,
    }
  );
  const { data: sightseeingSpotsData } = sightseeingSpotsResult;

  // Set sightseeing spots when data is available
  useEffect(
    () => {
      if (sightseeingSpotsData?.sightseeingSpots) {
        // Add sightseeing spots to sightseeing spots list
        const returnedSightseeingSpot =
          sightseeingSpotsData?.sightseeingSpots as SightseeingSpot[];
        const newSightseeingSpotInQueryLocale = uniqBy(
          [
            ...(sightseeingSpotsInQueryLocale?.[locale] || []),
            ...returnedSightseeingSpot,
          ],
          (sightseeingSpot) => sightseeingSpot.id
        );
        setSightseeingSpotsInQueryLocale({
          ...sightseeingSpotsInQueryLocale,
          [locale]: newSightseeingSpotInQueryLocale,
        });

        // Fetch more sightseeing spots if available
        if (returnedSightseeingSpot.length === sightseeingSpotsPageSize) {
          // By setting the page size another GraphQL query for the next page gets executed
          setSightseeingSpotsPageNumber(sightseeingSpotsPageNumber + 1);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sightseeingSpotsData]
  );

  useEffect(() => {
    setSightseeingSpotsPageNumber(0);
  }, [queryLocale]);

  const resetSightseeingSpots = () => {
    setSightseeingSpotsInQueryLocale({});
    setSightseeingSpotsPageNumber(0);
  };

  return {
    sightseeingSpotsInQueryLocale,
    resetSightseeingSpots,
  };
};

export default useSightseeingSpotsByCoordinates;
