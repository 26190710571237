import { useIonModal, useIonRouter } from "@ionic/react";
import { createContext, useContext, useEffect, useState } from "react";
import { map, find, includes } from "lodash-es";

import useAppState from "../hooks/useAppState";
import useRoutes from "../hooks/useRoutes";
import useAuthStore from "../stores/useAuthStore";
import {
  TourCreationRequestStatisticsByUser,
  TourCreationRequestStatisticsPeriod,
  TourCreationRequestStatus,
  useGetTourCreationRequestStatisticsByUserLazyQuery,
  useTourCreationRequestUpdatedSubscription,
} from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import CreateTourSuccessModal from "../components/modals/CreateTourSuccessModal";
import { Tour } from "../interfaces/Interfaces";
import useError from "../hooks/useError";
import useToast from "../hooks/useToast";
import { MixpanelEvents, useMixpanel } from "./MixpanelContext";

const getNumberOfAvailableTourCreationRequests = (
  tourCreationRequestStatisticsByUser: TourCreationRequestStatisticsByUser
) => {
  const periods = [
    TourCreationRequestStatisticsPeriod.AllTime,
    TourCreationRequestStatisticsPeriod.Year,
    TourCreationRequestStatisticsPeriod.Week,
    TourCreationRequestStatisticsPeriod.Day,
  ];

  for (const period of periods) {
    const statistics = find(tourCreationRequestStatisticsByUser.statistics, [
      "period",
      period,
    ]);
    if (statistics) {
      const remainder =
        statistics.limit -
        statistics.success -
        tourCreationRequestStatisticsByUser?.inProgress;
      const isYearOrWeek = includes(
        [
          TourCreationRequestStatisticsPeriod.Year,
          TourCreationRequestStatisticsPeriod.Week,
        ],
        period
      );

      if (isYearOrWeek) {
        if (remainder <= 0) {
          return {
            remainder: 0,
            limit: statistics.limit,
          };
        }
      } else {
        return {
          remainder: remainder < 0 ? 0 : remainder,
          limit: statistics.limit,
        };
      }
    }
  }
};

const useValue = () => {
  const { isAppActive } = useAppState();
  const { tourPath } = useRoutes();
  const router = useIonRouter();
  const { handleBackendError } = useError();
  const { presentToast } = useToast();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [
    tourCreationRequestStatisticsByUser,
    setTourCreationRequestStatisticsByUser,
  ] = useState<TourCreationRequestStatisticsByUser>({
    inProgress: 0,
    statistics: [],
    userId: "",
  });
  const [createdTourId, setCreatedTourId] = useState<string>();
  const [
    numberOfAvailableTourCreationRequests,
    setNumberOfAvailableTourCreationRequests,
  ] = useState<{ remainder: number; limit: number }>();

  const [getTourCreationRequestStatisticsByUserQuery] =
    useGetTourCreationRequestStatisticsByUserLazyQuery();
  const { data: userTourCreationRequestUpdatedData } =
    useTourCreationRequestUpdatedSubscription({
      // subscribe when user is authenticated
      skip: !isAuthenticated,
    });

  const [present, dismiss] = useIonModal(CreateTourSuccessModal, {
    tourId: createdTourId,
    navigateToTour: (tour: Tour) => {
      const path = tourPath(tour);
      path && router.push(path);
    },
    onDismiss: () => dismiss(),
  });

  useEffect(
    () => {
      const getTourCreationRequestStatisticsByUser = async () => {
        await handleBackendError(async () => {
          const { data, error } =
            await getTourCreationRequestStatisticsByUserQuery({
              fetchPolicy: "no-cache",
            });

          if (error) return error;

          const statistics =
            data?.tourCreationRequest?.getTourCreationRequestStatisticsByUser;
          if (statistics) {
            setTourCreationRequestStatisticsByUser(statistics);
          }
        });
      };

      if (isAuthenticated && isAppActive) {
        getTourCreationRequestStatisticsByUser();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, isAppActive]
  );

  useEffect(
    () => {
      const tourCreationRequestUpdated =
        userTourCreationRequestUpdatedData?.tourCreationRequestUpdated;
      const createdTourId = tourCreationRequestUpdated?.datoTourId;
      const tourCreationRequestStatus = tourCreationRequestUpdated?.status;

      switch (tourCreationRequestStatus) {
        case TourCreationRequestStatus.Success:
          if (mixpanelEnabled) {
            mixpanel.track(MixpanelEvents.CREATE_TOUR_SUCCESS, {
              datoTourId: createdTourId,
            });
          }
          setTourCreationRequestStatisticsByUser({
            ...tourCreationRequestStatisticsByUser,
            inProgress: tourCreationRequestStatisticsByUser?.inProgress - 1,
            statistics: map(
              tourCreationRequestStatisticsByUser.statistics,
              (stat) => ({
                ...stat,
                success: stat?.success + 1,
              })
            ),
          });
          break;
        case TourCreationRequestStatus.Failure:
          if (mixpanelEnabled) {
            mixpanel.track(MixpanelEvents.CREATE_TOUR_FAIL, {
              datoTourId: createdTourId,
            });
          }
          presentToast(
            "createTour.failureMessageForTourCreationRequest",
            "danger"
          );
          setTourCreationRequestStatisticsByUser({
            ...tourCreationRequestStatisticsByUser,
            inProgress: tourCreationRequestStatisticsByUser?.inProgress - 1,
          });
          break;
      }

      if (createdTourId) setCreatedTourId(createdTourId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userTourCreationRequestUpdatedData]
  );

  useEffect(() => {
    const numberOfAvailableTourCreationRequests =
      getNumberOfAvailableTourCreationRequests(
        tourCreationRequestStatisticsByUser
      );

    setNumberOfAvailableTourCreationRequests(
      numberOfAvailableTourCreationRequests
    );
  }, [tourCreationRequestStatisticsByUser]);

  useEffect(
    () => {
      if (createdTourId) present();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createdTourId]
  );

  return {
    numberOfAvailableTourCreationRequests,
    tourCreationRequestStatisticsByUser,
    setTourCreationRequestStatisticsByUser,
  };
};

const TourCreationRequestContext = createContext(
  {} as ReturnType<typeof useValue>
);

const TourCreationRequestProvider: React.FC = ({ children }) => {
  return (
    <TourCreationRequestContext.Provider value={useValue()}>
      {children}
    </TourCreationRequestContext.Provider>
  );
};

const useTourCreationRequest = () => {
  return useContext(TourCreationRequestContext);
};

export { TourCreationRequestProvider, useTourCreationRequest };
