import { useEffect, useRef, useMemo, useState, memo } from "react";
import Map, {
  MapRef,
  NavigationControl,
  ViewState,
  Source,
  Layer,
  Marker,
  LngLatBounds,
} from "react-map-gl";
import { useTranslation } from "react-i18next";
import { useIonModal } from "@ionic/react";
import { useFormContext } from "react-hook-form";
import { useThrottledState } from "@react-hookz/web";

import { setMapLanguage } from "../../helpers/map-helpers";
import { useLocale } from "../../contexts/LocaleContext";
import { useCity } from "../../contexts/CityContext";
import AppButton from "../../components/buttons/AppButton";
import DrawingPolygonModal from "./DrawingPolygonModal";
import { getBbox } from "../../helpers/turf-helpers";
import SightseeingSpotMarkersWithSearchArea from "./SightseeingSpotMarkersWithSearchArea";

const navControlStyle = {
  right: 10,
  bottom: 10,
};

const LocationsPreviewMap: React.FC = () => {
  const mapRef = useRef<MapRef>(null);
  const { locale } = useLocale();
  const { currentCity } = useCity();
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  // Watch the polygon value from the form
  const polygon = watch("polygon");

  // Watch start and end points from the form
  const startPoint = watch("startPoint");
  const endPoint = watch("endPoint");

  const [present, dismiss] = useIonModal(DrawingPolygonModal, {
    currentCity,
    polygonFormFieldName: "polygon",
    startPointFormFieldName: "startPoint",
    endPointFormFieldName: "endPoint",
    setFormValue: setValue,
    watch,
    onDismiss: () => dismiss(),
  });

  const [viewState, setViewState] = useState<ViewState>({
    latitude: currentCity?.location?.latitude || 52.5142,
    longitude: currentCity?.location?.longitude || 13.39,
    zoom: 14,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
  });

  const [mapBounds, setMapBounds] = useThrottledState<
    LngLatBounds | null | undefined
  >(null, 1000);
  const [isMapReady, setIsMapReady] = useState<boolean>(false);

  // Set a different map center if the city changes
  useEffect(() => {
    if (currentCity && isMapReady) {
      mapRef.current?.flyTo({
        zoom: 14,
        bearing: 0,
        pitch: 0,
        padding: { top: 0, bottom: 0, left: 0, right: 0 },
        center: [
          currentCity.location?.longitude,
          currentCity.location?.latitude,
        ],
        duration: 5000,
      });
    }
  }, [currentCity, isMapReady]);

  // Fit map to bounds when polygon, start point, or end point changes
  useEffect(() => {
    if (!mapRef.current) return;

    // Collect all available coordinates
    const coordinates = [
      ...(polygon?.length ? polygon : []),
      ...(startPoint?.location
        ? [[startPoint.location.longitude, startPoint.location.latitude]]
        : []),
      ...(endPoint?.location
        ? [[endPoint.location.longitude, endPoint.location.latitude]]
        : []),
    ];

    // Only proceed if we have coordinates to fit
    if (coordinates.length) {
      const bbox = getBbox(coordinates)!;
      mapRef.current.fitBounds(bbox, {
        padding: { top: 30, bottom: 100, right: 60, left: 30 },
      });
    }
  }, [polygon, startPoint, endPoint]);

  const resizeMap = () => {
    mapRef.current?.resize();
  };

  const calculateMapBounds = () => {
    const currentMapBounds = mapRef?.current?.getBounds();
    setMapBounds(currentMapBounds);
  };

  // Create GeoJSON for the polygon
  const polygonData = useMemo(() => {
    if (!polygon) return null;

    return {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [polygon],
      },
    };
  }, [polygon]);

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={(evt) => {
        setViewState(evt.viewState);
        calculateMapBounds();
      }}
      attributionControl={false}
      reuseMaps={true}
      dragRotate={true}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      onLoad={(e) => {
        resizeMap();
        setMapLanguage(e, locale);
        setIsMapReady(true);
        calculateMapBounds();
      }}
    >
      <SightseeingSpotMarkersWithSearchArea
        viewState={viewState}
        mapBounds={mapBounds}
        queryRadius={10000}
        buttonClassName="bottom-0 left-1/2 transform -translate-x-1/2"
      />

      {polygonData && (
        <Source type="geojson" data={polygonData}>
          <Layer
            id="polygon-fill"
            type="fill"
            paint={{
              "fill-color": "#ef6c4e",
              "fill-opacity": 0.3,
            }}
          />
          <Layer
            id="polygon-outline"
            type="line"
            paint={{
              "line-color": "#ef6c4e",
              "line-width": 4,
            }}
          />
        </Source>
      )}

      {startPoint?.location && (
        <Marker
          longitude={startPoint.location.longitude}
          latitude={startPoint.location.latitude}
          anchor="bottom"
        >
          <div className="flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green-500 font-bold text-white shadow-lg">
            S
          </div>
        </Marker>
      )}

      {endPoint?.location && (
        <Marker
          longitude={endPoint.location.longitude}
          latitude={endPoint.location.latitude}
          anchor="bottom"
        >
          <div className="flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-red-500 font-bold text-white shadow-lg">
            E
          </div>
        </Marker>
      )}

      <NavigationControl style={navControlStyle} showCompass={false} />

      <div className="absolute bottom-8 left-1/2 z-10 -translate-x-1/2">
        <AppButton
          shape="round"
          onClick={() => present()}
          disabled={!currentCity}
        >
          {t("createTour.form.buttons.drawOnMap")}
        </AppButton>
      </div>
    </Map>
  );
};

export default memo(LocationsPreviewMap);
