import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDebouncedEffect, useDebouncedState } from "@react-hookz/web";

import { City } from "../interfaces/Interfaces";
import { useLocale } from "../contexts/LocaleContext";
import {
  useCityByCityIdQuery,
  useCityByMapboxIdQuery,
} from "../graphql/dato/__generated__/dato-graphql.generated";
import { getCurrentLocation } from "../helpers/geolocation-helpers";
import useAppState from "../hooks/useAppState";
import {
  getItemFromStorage,
  removeItemFromStorage,
  setItemToStorage,
} from "../helpers/storage-helpers";
import { getPlaceNameOrAddress } from "../helpers/place-search-helpers";
import axios from "../config/axios.config";

const useValue = () => {
  const { queryLocale } = useLocale();
  const { isAppActive } = useAppState();

  const [currentCity, setCurrentCity] = useState<City | null | undefined>();
  const [cityIdToReceive, setCityIdToReceive] = useState<
    string | null | undefined
  >();

  const [placeNameData, setPlaceNameData] = useDebouncedState<
    | {
        placeName?: string;
        resetAllData?: boolean;
        skipReceivingSuggestions?: boolean;
      }
    | null
    | undefined
  >(null, 500);
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any[] | undefined>([]);
  const [selectedPlaceId, setSelectedPlaceId] = useState<string | undefined>(
    ""
  );
  const [selectedPlace, setSelectedPlace] = useState<any | null | undefined>(
    null
  );

  const [cityByCityIdResult] = useCityByCityIdQuery({
    variables: {
      locale: queryLocale,
      cityId: cityIdToReceive,
    },
    pause: !cityIdToReceive,
    requestPolicy: "network-only",
  });
  const { data: cityByCityIdData } = cityByCityIdResult;

  const cityByMapboxIdVariables = useCallback(() => {
    return {
      locale: queryLocale,
      mapboxId: selectedPlace?.properties?.mapbox_id,
    };
  }, [queryLocale, selectedPlace]);

  const [cityByCoordinatesResult] = useCityByMapboxIdQuery({
    variables: cityByMapboxIdVariables(),
    pause: !selectedPlace,
    requestPolicy: "network-only",
  });
  const { data: cityByCoordinatesData } = cityByCoordinatesResult;

  useEffect(
    () => {
      if (cityByCityIdData?.city)
        setCurrentCityToStateAndLocalStorage(cityByCityIdData?.city as City);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cityByCityIdData]
  );

  useEffect(
    () => {
      if (cityByCoordinatesData) {
        if (cityByCoordinatesData?.city) {
          setCurrentCityToStateAndLocalStorage(
            cityByCoordinatesData.city as City
          );
        } else {
          const cityData = {
            name: getPlaceNameOrAddress(selectedPlace),
            location: {
              latitude:
                selectedPlace?.properties?.coordinates?.latitude ||
                selectedPlace?.center?.[1],
              longitude:
                selectedPlace?.properties?.coordinates?.longitude ||
                selectedPlace?.center?.[0],
            },
            mapboxId: selectedPlace?.properties?.mapbox_id,
          } as City;
          setCurrentCityToStateAndLocalStorage(cityData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cityByCoordinatesData]
  );

  // use debounced effect to be sure that the url doesn't contain city id to receive
  useDebouncedEffect(
    () => {
      const getUserCity = async () => {
        // check if the current city is set to local storage and use it
        const currentCity = await getItemFromStorage("currentCity");
        if (currentCity?.mapboxId) {
          setCurrentCity(currentCity);
          return;
        }

        // determine mapboxId of the city. Needed for existing users who have the city set in local storage without mapboxId
        // Can be removed after all users have updated the app to next version after 4.9.0
        if (currentCity) {
          try {
            const options = {
              params: {
                types: "place",
                access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
                language: queryLocale,
                limit: 1,
                proximity: `${currentCity.location.longitude},${currentCity.location.latitude}`,
              },
            };

            const response = await axios.get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                currentCity.name
              )}.json`,
              options
            );

            const feature = response?.data?.features?.[0];
            if (feature) {
              setCurrentCityToStateAndLocalStorage({
                ...currentCity,
                mapboxId: feature.properties.mapbox_id,
              });
            }
          } catch (error) {
            console.error("Error getting mapbox ID:", error);
          }

          return;
        }

        // get the current city by the user location if the city is not set to local storage
        let coordinates;
        try {
          coordinates = await getCurrentLocation();
          if (coordinates) getCityByCoordinatesAndSet(coordinates);
        } catch (e) {
          return;
        }
      };

      if (isAppActive) {
        // If cityIdToReceive exists get city by id.
        // If cityIdToReceive doesn't exist get the city from local storage or by the user location
        if (!cityIdToReceive) getUserCity();
      } else {
        // If app isn't active reset cityIdToReceive to avoid receiving the city again after the app becomes active
        setCityIdToReceive(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAppActive, cityIdToReceive],
    100
  );

  useEffect(
    () => {
      const fetchSuggestions = async () => {
        if (
          placeNameData?.placeName &&
          !placeNameData?.skipReceivingSuggestions
        ) {
          const userLocation = await getCurrentLocation();
          const options = {
            params: {
              q: placeNameData?.placeName,
              types: "city,place",
              access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
              session_token: "dbffba99-7a16-4f91-8f1c-912755456ab9",
              language: queryLocale,
              ...(userLocation
                ? {
                    proximity: `${userLocation.longitude},${userLocation.latitude}`,
                  }
                : {}),
              limit: 5,
            },
            cache: false as const,
          };
          const response = await axios.get(
            "https://api.mapbox.com/search/searchbox/v1/suggest",
            options
          );
          setSuggestions(response?.data?.suggestions);
          // Open the popup with the results.
          // If the suggestions array is empty, the user will see the message that the results were not found.
          setIsOptionsVisible(true);
        }
        // reset all previously selected data if the user clears input
        else if (!placeNameData?.placeName && placeNameData?.resetAllData) {
          setSelectedPlaceId("");
          setSelectedPlace(null);
          setIsOptionsVisible(false);
          setCurrentCity(null);
          removeItemFromStorage("currentCity");
        }
      };

      fetchSuggestions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placeNameData]
  );

  useEffect(() => {
    const fetchPlace = async () => {
      if (selectedPlaceId) {
        const options = {
          params: {
            access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
            session_token: "dbffba99-7a16-4f91-8f1c-912755456ab9",
            language: queryLocale,
          },
        };

        const response = await axios.get(
          `https://api.mapbox.com/search/searchbox/v1/retrieve/${selectedPlaceId}`,
          options
        );

        setSelectedPlace(response?.data?.features?.[0]);
        setIsOptionsVisible(false);
      }
    };

    fetchPlace();
  }, [selectedPlaceId, queryLocale]);

  const setCurrentCityToStateAndLocalStorage = (city?: City) => {
    setCurrentCity(city);
    setItemToStorage("currentCity", city);
  };

  const getCityByCoordinatesAndSet = async (coordinates: {
    longitude: number;
    latitude: number;
  }) => {
    const options = {
      params: {
        types: "place",
        access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        language: queryLocale,
        limit: 1,
      },
    };

    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.longitude},${coordinates.latitude}.json`,
      options
    );

    setSelectedPlace(response?.data?.features?.[0]);
  };

  return {
    // current city
    currentCity,
    setCityIdToReceive,
    getCityByCoordinatesAndSet,
    setCurrentCityToStateAndLocalStorage,

    // city search
    placeNameData,
    setPlaceNameData,
    isOptionsVisible,
    setIsOptionsVisible,
    suggestions,
    setSelectedPlaceId,
    selectedPlace,
  };
};

const CityContext = createContext({} as ReturnType<typeof useValue>);

const CityProvider: React.FC = ({ children }) => {
  return (
    <CityContext.Provider value={useValue()}>{children}</CityContext.Provider>
  );
};

const useCity = () => {
  return useContext(CityContext);
};

export { CityProvider, useCity };
