import { IonContent, useIonRouter, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { omit } from 'lodash-es';

import AuthLayout from '../layouts/AuthLayout';
import {
  useSignInMutation
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import AppInput from '../components/form/AppInput';
import { getBackendValidationErrors } from '../helpers/error-helpers';
import { BackendValidationError } from '../interfaces/Interfaces';
import { setItemToStorage } from '../helpers/storage-helpers';
import useRoutes from '../hooks/useRoutes';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../constants';
import GoogleSingInButton from '../components/buttons/GoogleSingInButton';
import AppleSingInButton from '../components/buttons/AppleSingInButton';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import { checkReturnedRegistrationData } from '../helpers/auth-helpers';
import { useAuth } from '../contexts/AuthContext';
import AppButton from '../components/buttons/AppButton';
import FacebookSingInButton from '../components/buttons/FacebookSingInButton';

export interface LoginForm {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { localePath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const [signInMutation] = useSignInMutation();
  const { setCurrentUserWithRelatedData } = useAuth();

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);
  const { register, reset, handleSubmit, formState: { errors } } = useForm<LoginForm>();

  const signIn = async (input: LoginForm) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.LOG_IN_VIA_EMAIL_AND_PASSWORD);
    }

    try {
      const { data } = await signInMutation({ variables: { input }});
      const me = data?.auth?.signIn;

      if (me) {
        checkReturnedRegistrationData({ email: input.email } as any, me);

        if (mixpanelEnabled && me) {
          mixpanel.track(MixpanelEvents.LOG_IN_SUCCESS, {
            sentEmail: input?.email,
            returnedEmail: me.email
          });
        }

        setCurrentUserWithRelatedData(me);
      } else {
        setCurrentUserWithRelatedData(me);

        await setItemToStorage('sentRegistrationData', omit(input, 'password'));
        router.push(localePath('sign-up/confirmation'));
      }
    } catch (e: any) {
      const backendErrors = getBackendValidationErrors(e);
      setBackendErrors(backendErrors);
    }
  };

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_LOG_IN);
    }
  });

  useIonViewWillLeave(() => {
    reset();
  });

  return (
    <AuthLayout titleTranslationPath="authFlow.login.title">
      <IonContent>
        <div className="mx-auto flex max-w-xl flex-col p-5">
          <form onSubmit={handleSubmit(signIn)} className="flex flex-col">
            <AppInput
              placeholder="authFlow.form.email.placeholder"
              name="email"
              register={register}
              validators={{
                required: t("authFlow.form.email.errors.required"),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t("authFlow.form.email.errors.invalid"),
                },
              }}
              frontendErrors={errors}
              backendErrors={backendErrors}
              trimAllSpaces={true}
            />
            <AppInput
              placeholder="authFlow.form.password.placeholder"
              name="password"
              type="password"
              register={register}
              validators={{
                required: t("authFlow.form.password.errors.required"),
                pattern: {
                  value: PASSWORD_PATTERN,
                  message: t("authFlow.form.password.errors.incorrect"),
                },
              }}
              frontendErrors={errors}
              backendErrors={backendErrors}
            />

            <div className="mb-5">
              <Link
                className="cursor-pointer text-[1rem] font-medium text-primary"
                to={localePath("reset-password")}
              >
                {t("authFlow.login.buttons.forgotPassword")}
              </Link>
            </div>

            <AppButton type="submit" className="w-full">
              {t("authFlow.login.buttons.login")}
            </AppButton>
          </form>

          <div className="my-7 flex items-center gap-4 text-[1rem] font-bold text-[#D6D6D6]">
            <div className="flex-1 border-t-[1px] border-[#D6D6D6]" />
            {t("dictionary.or")}
            <div className="flex-1 border-t-[1px] border-[#D6D6D6]" />
          </div>

          <GoogleSingInButton source="login" />
          <AppleSingInButton source="login" />
          <FacebookSingInButton source="login" />

          <div className="pt-[18px] text-center text-[0.75em] text-[#414254]">
            <span>{t("authFlow.login.noAccountMessage")} </span>
            <Link
              className="cursor-pointer font-bold"
              to={localePath("sign-up")}
            >
              {t("authFlow.login.buttons.goToSignUp")}
            </Link>
          </div>
        </div>
      </IonContent>
    </AuthLayout>
  );
};

export default LoginPage;
