import { Geolocation } from "@capacitor/geolocation";
import { get, pick, isEmpty } from "lodash-es";

const getCurrentLocation = async () => {
  try {
    const currentPosition = await Geolocation.getCurrentPosition();
    const location = pick(get(currentPosition, "coords"), [
      "latitude",
      "longitude",
    ]);
    return isEmpty(location) ? null : location;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export { getCurrentLocation };
