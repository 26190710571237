import { useEffect, useState } from "react";
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { some } from "lodash-es";
import { GraphQLError } from "graphql/error";

import { getItemFromStorage } from "../helpers/storage-helpers";
import authMiddleware from "../middlewares/auth.middleware";
import languageMiddleware from "../middlewares/language.middleware";
import useAppState from "./useAppState";
import useAuthStore from "../stores/useAuthStore";
import appVersionMiddleware from "../middlewares/app-version.middleware";
import timezoneMiddleware from "../middlewares/timezone.middleware";
import useGroupSharingStore from "../stores/useGroupSharingStore";
import useStoryExplorationStore from "../stores/useStoryExplorationStore";

const useBackend = () => {
  const { isAppActive } = useAppState();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const setMe = useAuthStore((state) => state.setMe);
  const setMyGroupSharing = useGroupSharingStore(
    (state) => state.setMyGroupSharing
  );
  const setIsExploreModeEnabled = useStoryExplorationStore(
    (state) => state.setIsExploreModeEnabled
  );

  const [apolloClient, setApolloClient] = useState<any>();
  const [wsConnectionStatus, setWsConnectionStatus] = useState<string>();

  const link = () => {
    const httpLink = new HttpLink({
      uri: `${process.env.REACT_APP_BACKEND_API_URL!}/graphql`,
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${process.env.REACT_APP_WS_BACKEND_API_URL!}/graphql`,
        retryAttempts: 100,
        retryWait: async (retries) => {
          // Maximum interval between retries is 30 sec
          // Backend sends the subscription with the supported app version in 1 minute after starting
          const delay = Math.min(1000 * Math.pow(1.5, retries), 30000);
          await new Promise((resolve) => setTimeout(resolve, delay));
        },
        shouldRetry: () => true,
        connectionParams: async () => {
          const me = await getItemFromStorage("me");
          const accessToken = me?.token;

          return {
            ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
          };
        },
        keepAlive: 20000, // Send a ping every 20 seconds
        on: {
          connected: () => setWsConnectionStatus("connected"),
          connecting: () => setWsConnectionStatus("connecting"),
          closed: () => setWsConnectionStatus("disconnected"),
          error: (e) => {
            console.error("WebSocket error:", e);
            setWsConnectionStatus("error");
          },
        },
      })
    );

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        if (
          some(
            graphQLErrors,
            (graphQLError: GraphQLError & { statusCode?: number }) =>
              graphQLError.statusCode === 401
          )
        ) {
          setMe(null);
          setMyGroupSharing(null);
          setIsExploreModeEnabled(false);
        }
      }
    });

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );

    return from([
      authMiddleware,
      languageMiddleware,
      appVersionMiddleware,
      timezoneMiddleware,
      errorLink,
      link,
    ]);
  };

  useEffect(
    () => {
      // recreate link if user authentication status or app status changes
      if (isAppActive) {
        setApolloClient(
          new ApolloClient({
            link: link(),
            cache: new InMemoryCache(),
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAppActive, isAuthenticated]
  );

  useEffect(() => {
    console.log("WebSocket connection status:", wsConnectionStatus);
  }, [wsConnectionStatus]);

  return { apolloClient };
};

export default useBackend;
