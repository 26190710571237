// values from the "properties" and "geometry" fields get used for the mapbox search API.
// values "text" and "place_name" get used for the mapbox places API.
const getPlaceNameOrAddress = (suggestion: any) => {
  return (
    suggestion?.properties?.name ||
    suggestion?.place_name ||
    suggestion?.properties?.full_address ||
    suggestion?.text ||
    suggestion?.properties?.place_formatted ||
    suggestion?.properties?.address
  );
};

export { getPlaceNameOrAddress };
