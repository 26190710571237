import { IonContent, useIonRouter, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { omit, trim } from 'lodash-es';

import AuthLayout from '../layouts/AuthLayout';
import AppInput from '../components/form/AppInput';
import { getBackendValidationErrors } from '../helpers/error-helpers';
import { BackendValidationError } from '../interfaces/Interfaces';
import { setItemToStorage } from '../helpers/storage-helpers';
import useRoutes from '../hooks/useRoutes';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../constants';
import { useSignUpMutation } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import GoogleSingInButton from '../components/buttons/GoogleSingInButton';
import AppleSingInButton from '../components/buttons/AppleSingInButton';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import AppButton from '../components/buttons/AppButton';
import FacebookSingInButton from '../components/buttons/FacebookSingInButton';

export interface SignUpForm {
  email: string;
  password: string;
  profile: {
    firstName: string;
    lastName: string;
  }
}

const SignUpPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { localePath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const [signUpMutation] = useSignUpMutation();

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);
  const { register, reset, handleSubmit, formState: { errors } } = useForm<SignUpForm>();

  const signUp = async (input: SignUpForm) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.SIGN_UP_VIA_EMAIL_AND_PASSWORD);
    }

    try {
      const inputWithTrimmedProfileData = {
        ...input,
        profile: {
          firstName: trim(input.profile.firstName),
          lastName: trim(input.profile.lastName),
        }
      }

      const { data } = await signUpMutation({ variables: {
        input: inputWithTrimmedProfileData,
      }});
      const signUp = data?.auth?.signUp;

      if (signUp) {
        await setItemToStorage('sentRegistrationData', omit(inputWithTrimmedProfileData, 'password'));
        router.push(localePath('sign-up/confirmation'));
      }
    } catch (e: any) {
      const backendErrors = getBackendValidationErrors(e);
      setBackendErrors(backendErrors);
    }
  };

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_SIGN_UP);
    }
  });

  useIonViewWillLeave(() => {
    reset();
  });

  return (
    <AuthLayout titleTranslationPath="authFlow.signUp.title">
      <IonContent>
        <div className="mx-auto flex max-w-xl flex-col p-5">
          <form onSubmit={handleSubmit(signUp)} className="flex flex-col">
            <AppInput
              placeholder="authFlow.form.firstName.placeholder"
              name="profile.firstName"
              register={register}
              validators={{
                required: t("authFlow.form.firstName.errors.required"),
              }}
              frontendErrors={errors}
              backendErrors={backendErrors}
              trimSpacesExceptLastOne={true}
            />
            <AppInput
              placeholder="authFlow.form.lastName.placeholder"
              name="profile.lastName"
              register={register}
              validators={{
                required: t("authFlow.form.lastName.errors.required"),
              }}
              frontendErrors={errors}
              backendErrors={backendErrors}
              trimSpacesExceptLastOne={true}
            />
            <AppInput
              placeholder="authFlow.form.email.placeholder"
              name="email"
              register={register}
              validators={{
                required: t("authFlow.form.email.errors.required"),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t("authFlow.form.email.errors.invalid"),
                },
              }}
              frontendErrors={errors}
              backendErrors={backendErrors}
              trimAllSpaces={true}
            />
            <AppInput
              placeholder="authFlow.form.password.placeholder"
              name="password"
              type="password"
              register={register}
              validators={{
                required: t("authFlow.form.password.errors.required"),
                pattern: {
                  value: PASSWORD_PATTERN,
                  message: t("authFlow.form.password.errors.pattern"),
                },
              }}
              frontendErrors={errors}
              backendErrors={backendErrors}
            />

            <AppButton type="submit" className="w-full">
              {t("authFlow.signUp.buttons.signUp")}
            </AppButton>
          </form>

          <div className="my-7 flex items-center gap-4 text-[1rem] font-bold text-[#D6D6D6]">
            <div className="flex-1 border-t-[1px] border-[#D6D6D6]" />
            {t("dictionary.or")}
            <div className="flex-1 border-t-[1px] border-[#D6D6D6]" />
          </div>

          <GoogleSingInButton source="sign-up" />
          <AppleSingInButton source="sign-up" />
          <FacebookSingInButton source="sign-up" />

          <div className="pt-[18px] text-center text-[0.75em] text-[#414254]">
            <span>{t("authFlow.legalMessage.byContinuing")} </span>
            <a
              href="https://www.guidable.com/terms-of-service"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer text-primary"
            >
              {t("authFlow.legalMessage.termsOfUse")}
            </a>
            <span> {t("authFlow.legalMessage.and")} </span>
            <a
              href="https://guidable.com/datenschutz"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer text-primary"
            >
              {t("authFlow.legalMessage.privacyPolicy")}
            </a>
            <span> {t("authFlow.legalMessage.agreed")}</span>
          </div>

          <div className="pt-[18px] text-center text-[0.75em] text-[#414254]">
            <span>{t("authFlow.signUp.hasAccountMessage")} </span>
            <Link className="cursor-pointer font-bold" to={localePath("login")}>
              {t("authFlow.signUp.buttons.goToLogin")}
            </Link>
          </div>
        </div>
      </IonContent>
    </AuthLayout>
  );
};

export default SignUpPage;
