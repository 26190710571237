import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  IonBackdrop,
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonList,
} from "@ionic/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { locationSharp, close } from "ionicons/icons";

import { useCity } from "../../contexts/CityContext";
import { getPlaceNameOrAddress } from "../../helpers/place-search-helpers";
import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";

const AppCitySearch: React.FC<{ additionalButton?: JSX.Element }> = ({
  additionalButton,
}) => {
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const {
    currentCity,
    placeNameData,
    setPlaceNameData,
    isOptionsVisible,
    setIsOptionsVisible,
    selectedPlace,
    suggestions,
    setSelectedPlaceId,
  } = useCity();

  const { control, watch, reset, getValues } = useForm<{
    placeName: string;
    skipReceivingSuggestions: boolean;
    resetAllData: boolean;
  }>();

  useEffect(
    () => {
      const subscription = watch((value) => {
        setPlaceNameData(value);
      });
      return () => subscription.unsubscribe();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch]
  );

  useEffect(
    () => {
      if (currentCity) {
        reset({
          placeName: currentCity?.name as string,
          skipReceivingSuggestions: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCity]
  );

  useEffect(
    () => {
      if (selectedPlace) {
        reset({
          placeName: getPlaceNameOrAddress(selectedPlace),
          skipReceivingSuggestions: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPlace]
  );

  useEffect(
    () => {
      if (placeNameData?.resetAllData) {
        reset({
          placeName: "",
          skipReceivingSuggestions: false,
          resetAllData: false,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placeNameData]
  );

  return (
    <div className="w-full">
      <Controller
        name="placeName"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <div className="relative z-10">
            <input
              className="h-[56px] w-full rounded-full bg-[rgba(255,255,255,0.94)] py-2 pl-9 pr-20 text-[0.875rem] font-semibold text-[#687582] shadow-[0_10px_12px_-8px_rgba(0,0,0,0.10)] backdrop-blur focus:outline-none"
              id="placeName"
              placeholder={t("citySearch.form.placeName.placeholder") || ""}
              autoComplete="off"
              type="search"
              {...field}
              onChange={(e) => {
                field.onChange(e);
                reset({
                  placeName: e.target.value,
                  skipReceivingSuggestions: false,
                  resetAllData: !e.target.value,
                });
              }}
              onFocus={() => {
                if (mixpanelEnabled) {
                  mixpanel.track(MixpanelEvents.CITY_SEARCH_BAR_FOCUSED);
                }
              }}
            />

            <IonIcon
              icon={locationSharp}
              className="absolute left-3 top-1/2 -translate-y-1/2 text-[1.25rem] text-[#687582]"
            />

            <IonButtons className="absolute right-2 top-[3px] h-[50px]">
              {!!getValues("placeName") && (
                <IonButton
                  fill="clear"
                  shape="round"
                  style={{
                    "--padding-start": "5px",
                    "--padding-end": "5px",
                  }}
                  onClick={() => {
                    reset({ placeName: "", resetAllData: true });
                  }}
                >
                  <IonIcon
                    icon={close}
                    className="text-[1.25rem] text-[#687582]"
                  />
                </IonButton>
              )}

              {!!additionalButton && additionalButton}
            </IonButtons>
          </div>
        )}
      />

      <IonBackdrop
        className={clsx(
          "h-[100vh] w-[100vw]",
          isOptionsVisible ? "" : "hidden"
        )}
        onIonBackdropTap={() => {
          reset({
            placeName:
              currentCity?.name || getPlaceNameOrAddress(selectedPlace),
            skipReceivingSuggestions: true,
          });
          setIsOptionsVisible(false);
        }}
      />

      {isOptionsVisible && (
        <div className="relative rounded-[20px]">
          <IonList className="absolute top-0 z-[11] w-full cursor-pointer rounded-[20px] border-2 border-t-0 p-0">
            {!suggestions?.length && (
              <IonItem color="light" lines="none" className="text-[0.75em]">
                {t("citySearch.form.placeName.errors.noResultsFound")}
                &#160;
                <span className="font-bold">{getValues("placeName")}</span>.
              </IonItem>
            )}

            {!!suggestions?.length &&
              suggestions?.map((suggestion) => (
                <IonItem
                  color="light"
                  lines="none"
                  className="mb-2 text-[0.75em]"
                  key={suggestion?.mapbox_id}
                  onClick={() => {
                    if (mixpanelEnabled) {
                      mixpanel.track(
                        MixpanelEvents.CITY_SEARCH_BAR_SELECT_CITY,
                        {
                          name: suggestion?.name,
                          address:
                            suggestion?.full_address ||
                            suggestion?.place_formatted ||
                            suggestion?.address,
                        }
                      );
                    }
                    setSelectedPlaceId(suggestion?.mapbox_id);
                  }}
                >
                  <div>
                    <div className="font-bold">{suggestion?.name}</div>
                    <div>
                      {suggestion?.full_address ||
                        suggestion?.place_formatted ||
                        suggestion?.address}
                    </div>
                  </div>
                </IonItem>
              ))}
          </IonList>
        </div>
      )}
    </div>
  );
};

export default AppCitySearch;
