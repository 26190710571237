import { IonRange } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import StepHeader from "./StepHeader";
import "./DurationStep.scss";

const DurationStep: React.FC = () => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  return (
    <div>
      <StepHeader
        number={4}
        title={t("createTour.form.duration.title")}
        hint={t("createTour.form.duration.hint")}
      />
      <div className="relative mx-10 mb-10">
        <IonRange
          {...register("tourDuration")}
          color="primary"
          pin={true}
          pinFormatter={(value: number) =>
            `${value / 60} ${t("units.time.hours.fullForm")}`
          }
          min={30}
          max={300}
          step={30}
        />
        <div className="absolute bottom-0 left-0 right-0 flex justify-between text-[0.75rem] text-[#687582]">
          <span>30 {t("units.time.minutes.shortForm")}</span>
          <span>5 {t("units.time.hours.fullForm")}</span>
        </div>
      </div>
    </div>
  );
};

export default DurationStep;
