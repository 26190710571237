import { useEffect, useRef, useMemo, useState } from "react";
import Map, {
  Layer,
  Source,
  MapRef,
  ViewState,
  NavigationControl,
} from "react-map-gl";
import { useTranslation } from "react-i18next";
import { get, head, filter, round, ceil } from "lodash-es";
import { LineString, FeatureCollection, Position } from "geojson";
import { GeoJSONSource } from "mapbox-gl";
import { IonIcon } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { close as closeIcon } from "ionicons/icons";
import { along as turfAlong } from "@turf/along";
import { lineString as turfLineString } from "@turf/helpers";
import { length as turfLength } from "@turf/length";

import { setMapLanguage } from "../../helpers/map-helpers";
import { useLocale } from "../../contexts/LocaleContext";
import {
  calculateBearing,
  determineIsSignificantBearingChange,
  lerp,
  normalizeBearing,
} from "../../helpers/turf-helpers";
import TourStopMarkers from "../map/TourStopMarkers";
import { Tour } from "../../interfaces/Interfaces";
import BuildingLayer from "../map/BuildingLayer";
import PlayRouteIconButton from "../buttons/PlayRouteIconButton";
import PlayTourPreviewAudioButton from "../buttons/PlayTourPreviewAudioButton";
import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";
import guidableLogo from "../../assets/GU_Logo_RZ-RGB_Icon.svg";

const FRAME_RATE = 1000 / 100;
const DISTANCE_OF_ROUTE_PIECE = 2;
const DISTANS_UPDATING_FRAME_RATE = 1000 / 2;
const POSITION_SMOOTHING = 0.05;
const BEARING_SMOOTHING = 0.005;
const BEARING_QUEUE_SIZE = 25;
const BEARING_THRESHOLD = 10;

const getRouteCoordinates = (tour: Tour) => {
  if (!tour.routeGeoJson) return [];

  const route = tour.routeGeoJson as FeatureCollection<LineString>;
  return (
    get(
      head(
        filter(route.features, (data) => data.geometry.type === "LineString")
      ),
      "geometry.coordinates"
    ) || []
  );
};

const RouteAnimationModal: React.FC<{
  tour: Tour;
  hasPremiumAccess: boolean;
  onDismiss: () => void;
}> = ({ tour, hasPremiumAccess, onDismiss }) => {
  const mapRef = useRef<MapRef>(null);
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const initialViewState = useMemo(() => {
    const routeCoordinates = getRouteCoordinates(tour);

    return {
      latitude:
        routeCoordinates[0]?.[1] || tour?.tourStops?.[0]?.location?.latitude,
      longitude:
        routeCoordinates[0]?.[0] || tour?.tourStops?.[0]?.location?.longitude,
      zoom: 16.5,
      bearing: 0,
      pitch: 60,
      padding: { top: 0, bottom: 0, left: 0, right: 0 },
      minZoom: 14,
      maxZoom: 18,
    };
  }, [tour]);

  const [isMapReady, setIsMapReady] = useState(false);
  const [viewState, setViewState] = useState<ViewState>(initialViewState);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRouteEnded, setIsRouteEnded] = useState(false);
  const [isAudioEnded, setIsAudioEnded] = useState(false);
  const [currentDistance, setCurrentDistance] = useState<number | string>("0");

  const currentIndexRef = useRef(0);
  const animationRouteCoordinatesRef = useRef<Position[]>([]);
  const isPlayingRef = useRef(true);
  const mountedRef = useRef(true);

  const [routeCoordinates, routeString, numberOfRoutePieces] = useMemo(() => {
    const routeCoordinates = getRouteCoordinates(tour);
    if (!routeCoordinates.length) return [];
    const routeString = turfLineString(routeCoordinates);
    const numberOfRoutePieces = ceil(
      (turfLength(routeString, { units: "meters" }) || 0) /
        DISTANCE_OF_ROUTE_PIECE
    );
    return [routeCoordinates, routeString, numberOfRoutePieces];
  }, [tour]);

  useEffect(() => {
    if (isRouteEnded && isAudioEnded) {
      setIsPlaying(false);
      setIsRouteEnded(false);
      setIsAudioEnded(false);
    }
  }, [isRouteEnded, isAudioEnded]);

  useEffect(() => {
    // Update the ref when `isPlaying` changes
    isPlayingRef.current = isPlaying;

    if (
      !routeCoordinates?.length ||
      !routeString ||
      !numberOfRoutePieces ||
      !isPlaying
    )
      return;

    if (animationRouteCoordinatesRef.current.length === 0) {
      animationRouteCoordinatesRef.current = [routeCoordinates[0]];
    }

    let animationFrameId: number;
    let index = currentIndexRef.current;
    let currentBearing = 0;
    let targetBearingQueue: number[] = [];
    const currentCoordinates = turfAlong(
      routeString,
      index * DISTANCE_OF_ROUTE_PIECE,
      {
        units: "meters",
      }
    ).geometry.coordinates;
    let currentLng = !index ? routeCoordinates[0][0] : currentCoordinates[0];
    let currentLat = !index ? routeCoordinates[0][1] : currentCoordinates[1];
    let lastTime = 0;
    let lastDistanceUpdatingTime = 0;

    const animateRoute = (timestamp: number) => {
      const map = mapRef.current?.getMap();
      if (!map || !mountedRef.current || !isPlayingRef.current) return;

      if (!lastTime) lastTime = timestamp;
      const deltaTime = timestamp - lastTime;

      if (deltaTime >= FRAME_RATE && index < numberOfRoutePieces) {
        const nextCoordinate = turfAlong(
          routeString,
          index * DISTANCE_OF_ROUTE_PIECE,
          {
            units: "meters",
          }
        );
        const currentCoordinate = [currentLng, currentLat];

        if (!lastDistanceUpdatingTime) lastDistanceUpdatingTime = timestamp;
        const deltaTimeOfDistanceUpdating =
          timestamp - lastDistanceUpdatingTime;
        if (deltaTimeOfDistanceUpdating >= DISTANS_UPDATING_FRAME_RATE) {
          if (mountedRef.current) {
            setCurrentDistance(
              round((index * DISTANCE_OF_ROUTE_PIECE) / 1000, 1).toFixed(1)
            );
          }
          lastDistanceUpdatingTime = timestamp;
        }

        const animatedLineSource = map.getSource(
          "animated-line"
        ) as GeoJSONSource;
        if (animatedLineSource) {
          animationRouteCoordinatesRef.current.push(
            nextCoordinate.geometry.coordinates
          );
          animatedLineSource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: animationRouteCoordinatesRef.current,
            },
          });
        }

        const currentPositionSource = map.getSource(
          "current-position"
        ) as GeoJSONSource;
        if (currentPositionSource) {
          currentPositionSource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: nextCoordinate.geometry.coordinates,
            },
          });
        }

        const newTargetBearing = calculateBearing(
          currentCoordinate,
          nextCoordinate.geometry.coordinates
        );

        const isSignificantBearingChange = determineIsSignificantBearingChange(
          newTargetBearing,
          targetBearingQueue[targetBearingQueue.length - 1] ?? currentBearing,
          BEARING_THRESHOLD
        );

        if (isSignificantBearingChange) {
          targetBearingQueue.push(newTargetBearing);
          if (targetBearingQueue.length > BEARING_QUEUE_SIZE) {
            targetBearingQueue.shift();
          }
        }

        const targetBearing = targetBearingQueue[0] || currentBearing;

        const bearingDiff =
          ((targetBearing - currentBearing + 540) % 360) - 180;
        currentBearing += bearingDiff * BEARING_SMOOTHING;
        currentLng +=
          (nextCoordinate.geometry.coordinates[0] - currentLng) *
          POSITION_SMOOTHING;
        currentLat +=
          (nextCoordinate.geometry.coordinates[1] - currentLat) *
          POSITION_SMOOTHING;

        const currentCenter = map.getCenter();
        const lerpedLng = lerp(currentCenter.lng, currentLng, 0.2);
        const lerpedLat = lerp(currentCenter.lat, currentLat, 0.2);
        const normalizedBearing = normalizeBearing(
          currentBearing,
          map.getBearing()
        );
        const lerpedBearing = lerp(map.getBearing(), normalizedBearing, 0.01);

        setViewState((viewState) => ({
          ...viewState,
          latitude: lerpedLat,
          longitude: lerpedLng,
          bearing: lerpedBearing,
        }));

        index++;
        currentIndexRef.current = index;
        lastTime = timestamp;

        if (index >= numberOfRoutePieces && mountedRef.current) {
          setIsRouteEnded(true);
          currentIndexRef.current = 0;
          animationRouteCoordinatesRef.current = [];
          return;
        }
      }

      animationFrameId = requestAnimationFrame(animateRoute);
    };

    if (isPlayingRef.current) {
      animationFrameId = requestAnimationFrame(animateRoute);
    }

    return () => {
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };
  }, [isPlaying, routeCoordinates, routeString, numberOfRoutePieces]);

  useEffect(
    () => {
      if (mixpanelEnabled) {
        mixpanel.track(MixpanelEvents.VIEW_TOUR_PREVIEW, {
          tourId: tour?.id,
          tourTitle: tour?.title,
        });
      }

      return () => {
        mountedRef.current = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="relative h-full w-full">
      {!isMapReady && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-[51] flex items-center justify-center bg-white">
          <img
            src={guidableLogo}
            alt="guidable – stories to explore"
            className="w-[150px]"
          />
        </div>
      )}
      <Map
        ref={mapRef}
        {...viewState}
        onMove={(evt) => {
          setViewState(evt.viewState);
        }}
        attributionControl={false}
        dragRotate={true}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onLoad={(e) => {
          e.target.resize();
          setMapLanguage(e, locale);
          setIsPlaying(true);
          setIsMapReady(true);
        }}
      >
        <div
          className="relative"
          style={{
            marginTop: "var(--ion-safe-area-top)",
          }}
        >
          <IonButton
            shape="round"
            className="absolute left-[22px] top-2.5 z-50"
            onClick={onDismiss}
          >
            <IonIcon slot="icon-only" icon={closeIcon} />
          </IonButton>

          <PlayRouteIconButton
            isPlaying={isPlaying}
            className="top-[60px]"
            onClick={() => setIsPlaying(!isPlaying)}
          />

          <PlayTourPreviewAudioButton
            tour={tour}
            hasPremiumAccess={hasPremiumAccess}
            isPlaying={isPlaying}
            isAudioEnded={isAudioEnded}
            setIsAudioEnded={setIsAudioEnded}
          />

          <div className="absolute right-12 top-2.5 z-50 flex h-[64px] w-[120px] items-center justify-center rounded-[8px] bg-white px-4 text-[1.25rem] font-semibold text-gray-900">
            <div>
              {currentDistance}
              <span className="ml-[2px] text-[0.75rem] font-medium">
                {t("tour.kilometersInShortForm")}
              </span>
            </div>
          </div>
        </div>

        <NavigationControl
          style={{
            marginTop: "var(--ion-safe-area-top)",
            position: "absolute",
            top: "10px",
            right: 0,
          }}
          showCompass={false}
        />

        <Source
          id="mapbox-dem"
          type="raster-dem"
          url="mapbox://mapbox.mapbox-terrain-dem-v1"
          tileSize={512}
        />

        <BuildingLayer />

        <TourStopMarkers tour={tour} displayPlaceName={true} />

        <Source
          key={`constant-line`}
          type="geojson"
          data={{
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: routeCoordinates,
            },
          }}
        >
          <Layer
            type="line"
            layout={{
              "line-cap": "butt",
            }}
            paint={{
              "line-color": "#ef6c4e",
              "line-width": 5,
              "line-opacity": 0.4,
            }}
          />
        </Source>

        <Source
          id="animated-line"
          type="geojson"
          data={{
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          }}
        >
          <Layer
            type="line"
            layout={{
              "line-cap": "butt",
            }}
            paint={{
              "line-color": "#ef6c4e",
              "line-width": 5,
            }}
          />
        </Source>

        <Source
          id="current-position"
          type="geojson"
          data={{
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [],
            },
          }}
        >
          <Layer
            type="circle"
            paint={{
              "circle-radius": 6,
              "circle-color": "#ef6c4e",
              "circle-stroke-width": 0.5,
              "circle-stroke-color": "#E14F84",
              "circle-blur": 0.2,
            }}
          />
        </Source>
      </Map>
    </div>
  );
};

export default RouteAnimationModal;
