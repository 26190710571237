import { memo, useEffect } from "react";
import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import confetti from "canvas-confetti";

import { Tour } from "../../interfaces/Interfaces";
import useTour from "../../hooks/useTour";
import AppButton from "../buttons/AppButton";

const CreateTourSuccessModal: React.FC<{
  tourId?: string;
  navigateToTour: (tour: Tour) => void;
  onDismiss: () => void;
}> = ({ tourId, navigateToTour, onDismiss }) => {
  const { t } = useTranslation();
  const { tour } = useTour({ tourId });

  useEffect(() => {
    confetti({
      particleCount: 500,
      spread: 100,
      origin: { y: 0.6 },
    });
  }, []);

  return (
    <IonPage className="min-h-[100vh]">
      <IonContent color="dark" className="ion-padding">
        <div className="min-h-full p-5">
          <IonButton
            fill="clear"
            color="medium"
            style={{
              "--padding-start": 0,
            }}
            onClick={() => onDismiss()}
          >
            <IonIcon slot="icon-only" icon={chevronBackOutline} />
          </IonButton>

          <div className="text-center text-white">
            <p className="mb-7 pt-10 text-[2.5rem] font-bold">
              {t("createTourSuccessModal.messageOne")}
            </p>
            <div className="mb-10 text-[100px]">🥳</div>
            <p className="mb-20 font-bold">
              {t("createTourSuccessModal.messageTwo")}
            </p>
            <AppButton
              shape="round"
              className="w-full"
              onClick={() => {
                navigateToTour(tour as Tour);
                onDismiss();
              }}
            >
              {t("createTourSuccessModal.buttons.exploreTour")}
            </AppButton>
          </div>

          <div
            style={{
              paddingBottom: "calc(var(--ion-safe-area-bottom) + 30px)",
            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(CreateTourSuccessModal);
