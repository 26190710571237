import { IonContent, useIonRouter, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AuthLayout from '../layouts/AuthLayout';
import AppInput from '../components/form/AppInput';
import { getBackendValidationErrors } from '../helpers/error-helpers';
import { BackendValidationError } from '../interfaces/Interfaces';
import { setItemToStorage } from '../helpers/storage-helpers';
import useRoutes from '../hooks/useRoutes';
import { EMAIL_PATTERN } from '../constants';
import { useResetPasswordMutation } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import AppButton from '../components/buttons/AppButton';

interface ResetPasswordForm {
  email: string;
}

const ResetPasswordPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { localePath } = useRoutes();
  const [resetPasswordMutation] = useResetPasswordMutation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);
  const { register, reset, handleSubmit, formState: { errors } } = useForm<ResetPasswordForm>();

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_RESET_PASSWORD);
    }
  });

  useIonViewWillLeave(() => {
    reset();
  });

  const resetPassword = async (input: ResetPasswordForm) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.RESET_PASSWORD);
    }

    try {
      const { data } = await resetPasswordMutation({ variables: { input }});
      const resetPassword = data?.auth?.resetPassword;

      if (resetPassword) {
        await setItemToStorage('resetPasswordEmail', input.email);
        router.push(localePath('reset-password/confirmation'));
      }
    } catch (e: any) {
      const backendErrors = getBackendValidationErrors(e);
      setBackendErrors(backendErrors);
    }
  };

  return (
    <AuthLayout titleTranslationPath="authFlow.resetPassword.title">
      <IonContent>
        <div className="mx-auto flex max-w-xl flex-col p-5">
          <form
            onSubmit={handleSubmit(resetPassword)}
            className="flex flex-col"
          >
            <AppInput
              placeholder="authFlow.form.email.placeholder"
              name="email"
              register={register}
              validators={{
                required: t("authFlow.form.email.errors.required"),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t("authFlow.form.email.errors.invalid"),
                },
              }}
              frontendErrors={errors}
              backendErrors={backendErrors}
              trimAllSpaces={true}
            />

            <span className="pb-[80px] text-[0.75rem] text-[#414254]">
              {t("authFlow.resetPassword.message")}
            </span>

            <AppButton type="submit" className="w-full">
              {t("authFlow.resetPassword.buttons.resetPassword")}
            </AppButton>
          </form>
        </div>
      </IonContent>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
