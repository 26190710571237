import { Controller, useForm, useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  IonBackdrop,
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonList,
} from "@ionic/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { close, locateSharp } from "ionicons/icons";
import { useDebouncedState } from "@react-hookz/web";
import { some, values } from "lodash";

import axios from "../../config/axios.config";
import { useCity } from "../../contexts/CityContext";
import { getPlaceNameOrAddress } from "../../helpers/place-search-helpers";
import { useLocale } from "../../contexts/LocaleContext";
import { getCurrentLocation } from "../../helpers/geolocation-helpers";
import useToast from "../../hooks/useToast";

const PlaceSearch: React.FC<{
  placeholderTranslationKey: string;
  icon: string;
  iconClassName?: string;
  inputClassName?: string;
  name: "startPoint" | "endPoint";
}> = ({
  placeholderTranslationKey,
  icon,
  iconClassName = "",
  inputClassName = "",
  name,
}) => {
  const { t } = useTranslation();
  const { queryLocale } = useLocale();
  const { currentCity } = useCity();
  const { setValue: parentFormSetValue, getValues: parentFormGetValues } =
    useFormContext();
  const { presentToast } = useToast();

  const { control, watch, reset, getValues } = useForm<{
    placeName: string;
    skipReceivingSuggestions: boolean;
    resetAllData: boolean;
  }>();

  const [suggestions, setSuggestions] = useState<any[] | undefined>([]);
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
  const [placeNameData, setPlaceNameData] = useDebouncedState<
    | {
        placeName?: string;
        resetAllData?: boolean;
        skipReceivingSuggestions?: boolean;
      }
    | null
    | undefined
  >(null, 500);
  const [selectedPlace, setSelectedPlace] = useState<any | null | undefined>(
    null
  );
  const [selectedPlaceId, setSelectedPlaceId] = useState<string | undefined>(
    ""
  );

  useEffect(
    () => {
      const fetchSuggestions = async () => {
        if (
          placeNameData?.placeName &&
          !placeNameData?.skipReceivingSuggestions
        ) {
          const options = {
            params: {
              q: placeNameData?.placeName,
              proximity: `${currentCity?.location?.longitude},${currentCity?.location?.latitude}`,
              types: "street,address,poi",
              access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
              session_token: "dbffba99-7a16-4f91-8f1c-912755456ab9",
              language: queryLocale,
              limit: 5,
            },
            cache: false as const,
          };
          const response = await axios.get(
            "https://api.mapbox.com/search/searchbox/v1/suggest",
            options
          );
          setSuggestions(response?.data?.suggestions);
          // Open the popup with the results.
          // If the suggestions array is empty, the user will see the message that the results were not found.
          setIsOptionsVisible(true);
        }
        // reset all previously selected data if the user clears input
        else if (!placeNameData?.placeName && placeNameData?.resetAllData) {
          setSelectedPlaceId("");
          setSelectedPlace(null);
          setIsOptionsVisible(false);
        }
      };

      fetchSuggestions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placeNameData]
  );

  useEffect(() => {
    const fetchPlace = async () => {
      if (selectedPlaceId) {
        const options = {
          params: {
            access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
            session_token: "dbffba99-7a16-4f91-8f1c-912755456ab9",
            language: queryLocale,
          },
        };

        const response = await axios.get(
          `https://api.mapbox.com/search/searchbox/v1/retrieve/${selectedPlaceId}`,
          options
        );

        setSelectedPlace(response?.data?.features?.[0]);
        setIsOptionsVisible(false);
      }
    };

    fetchPlace();
  }, [selectedPlaceId, queryLocale]);

  useEffect(
    () => {
      const subscription = watch((value) => {
        setPlaceNameData(value);
      });
      return () => subscription.unsubscribe();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch]
  );

  useEffect(
    () => {
      if (currentCity) {
        reset({
          placeName: "",
          skipReceivingSuggestions: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCity]
  );

  useEffect(
    () => {
      const initialPoint = parentFormGetValues(name);
      if (initialPoint) {
        // Set the form value
        reset({
          placeName: initialPoint.name,
          skipReceivingSuggestions: true,
        });

        // Set the selected place with the initial coordinates
        setSelectedPlace({
          geometry: {
            coordinates: [
              initialPoint.location.longitude,
              initialPoint.location.latitude,
            ],
          },
          properties: {
            name: initialPoint.name,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name]
  );

  useEffect(
    () => {
      if (selectedPlace) {
        const placeName = getPlaceNameOrAddress(selectedPlace);

        // set to the local form
        reset({ placeName, skipReceivingSuggestions: true });

        // set to the tour creation form
        const point = {
          name: placeName,
          location: {
            latitude: selectedPlace.geometry.coordinates[1],
            longitude: selectedPlace.geometry.coordinates[0],
          },
        };
        parentFormSetValue(name, point);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPlace]
  );

  useEffect(
    () => {
      if (placeNameData?.resetAllData) {
        reset({
          placeName: "",
          skipReceivingSuggestions: false,
          resetAllData: false,
        });
        parentFormSetValue(name, null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placeNameData]
  );

  const setCurrentLocation = async () => {
    try {
      const currentLocation = await getCurrentLocation();

      if (!currentLocation) {
        presentToast(
          "createTour.form.setStartAndEndPoints.errors.locationOutsideOfTheCity",
          "danger"
        );
        return;
      }

      const options = {
        params: {
          types: "address",
          access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
          language: queryLocale,
          limit: 1,
        },
      };

      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentLocation.longitude},${currentLocation.latitude}.json`,
        options
      );

      const mapboxFeature = response?.data?.features?.[0];

      if (!mapboxFeature) {
        presentToast(
          "createTour.form.setStartAndEndPoints.errors.locationOutsideOfTheCity",
          "danger"
        );
        return;
      }

      const isCurrentLocationInsideSelectedCity = some(
        mapboxFeature?.context,
        (contextItem: any) => contextItem.mapbox_id === currentCity?.mapboxId
      );

      if (!isCurrentLocationInsideSelectedCity) {
        presentToast(
          "createTour.form.setStartAndEndPoints.errors.locationOutsideOfTheCity",
          "danger"
        );
        return;
      }

      setSelectedPlace(mapboxFeature);
    } catch (error) {
      presentToast(
        "createTour.form.setStartAndEndPoints.errors.locationOutsideOfTheCity",
        "danger"
      );
    }
  };

  return (
    <div className={clsx("w-full", inputClassName)}>
      <div className="flex w-full items-center justify-between">
        <Controller
          name="placeName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <div className="relative z-10 w-full">
              <input
                className="h-[56px] w-full rounded-full bg-[rgba(255,255,255,0.94)] px-9 py-2 text-[0.875rem] font-semibold text-[#687582] shadow-[0_10px_12px_-8px_rgba(0,0,0,0.10)] backdrop-blur focus:outline-none"
                id="placeName"
                placeholder={t(placeholderTranslationKey)!}
                autoComplete="off"
                type="search"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  reset({
                    placeName: e.target.value,
                    skipReceivingSuggestions: false,
                    resetAllData: !e.target.value,
                  });
                }}
              />

              <IonIcon
                icon={icon}
                className={clsx(
                  "absolute left-3 top-1/2 -translate-y-1/2 text-[1.25rem] text-[#687582]",
                  iconClassName
                )}
              />

              <IonButtons className="absolute right-2 top-[3px] h-[50px]">
                {!!getValues("placeName") && (
                  <IonButton
                    fill="clear"
                    shape="round"
                    style={{
                      "--padding-start": "5px",
                      "--padding-end": "5px",
                    }}
                    onClick={() => {
                      reset({ placeName: "", resetAllData: true });
                    }}
                  >
                    <IonIcon
                      icon={close}
                      className="text-[1.25rem] text-[#687582]"
                    />
                  </IonButton>
                )}
              </IonButtons>
            </div>
          )}
        />

        <IonButton
          shape="round"
          slot="icon-only"
          className="ml-2 h-[40px] w-[40px] min-w-[40px]"
          style={{
            "--padding-start": "10px",
            "--padding-end": "10px",
          }}
          onClick={() => setCurrentLocation()}
        >
          <IonIcon icon={locateSharp} className="h-full w-full" />
        </IonButton>
      </div>

      <IonBackdrop
        className={clsx(
          "h-[100vh] w-[100vw]",
          isOptionsVisible ? "" : "hidden"
        )}
        onIonBackdropTap={() => {
          reset({
            placeName: getPlaceNameOrAddress(selectedPlace),
            skipReceivingSuggestions: true,
          });
          setIsOptionsVisible(false);
        }}
      />

      {isOptionsVisible && (
        <div className="relative rounded-[20px]">
          <IonList className="absolute top-0 z-[11] w-full cursor-pointer rounded-[20px] border-2 border-t-0 p-0">
            {!suggestions?.length && (
              <IonItem color="light" lines="none" className="text-[0.75em]">
                {t("citySearch.form.placeName.errors.noResultsFound")}
                &#160;
                <span className="font-bold">{getValues("placeName")}</span>.
              </IonItem>
            )}

            {!!suggestions?.length &&
              suggestions?.map((suggestion) => (
                <IonItem
                  color="light"
                  lines="none"
                  className="mb-2 text-[0.75em]"
                  key={suggestion?.mapbox_id}
                  disabled={
                    !some(
                      values(suggestion?.context),
                      (contextItem: any) =>
                        contextItem.id === currentCity?.mapboxId
                    )
                  }
                  onClick={() => {
                    setSelectedPlaceId(suggestion?.mapbox_id);
                  }}
                >
                  <div>
                    <div className="font-bold">{suggestion?.name}</div>
                    <div>
                      {suggestion?.full_address ||
                        suggestion?.place_formatted ||
                        suggestion?.address}
                    </div>
                  </div>
                </IonItem>
              ))}
          </IonList>
        </div>
      )}
    </div>
  );
};

export default PlaceSearch;
