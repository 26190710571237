import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Map, { MapRef } from "react-map-gl";
import type { ViewState } from "react-map-gl";
import {
  useDebouncedEffect,
  useDebouncedState,
  useThrottledState,
} from "@react-hookz/web";
import { useIonModal, useIonRouter, useIonViewDidEnter } from "@ionic/react";
import {
  uniqBy,
  groupBy,
  values,
  partition,
  flatten,
  head,
  map,
  find,
  get,
  isBoolean,
  isEqual,
  round,
} from "lodash-es";
import { LngLatBounds, LngLatLike } from "mapbox-gl";
import { Capacitor } from "@capacitor/core";
import { useCustomCompareEffect } from "react-use";
import { circle } from "@turf/circle";
import bbox from "@turf/bbox";

import "./StoryMap.scss";
import ClusterMarkers from "../map/ClusterMarkers";
import StoryMapFilter from "../filters/StoryMapFilter";
import {
  getItemFromStorage,
  setItemToStorage,
} from "../../helpers/storage-helpers";
import SightseeingSpotMarkers from "./SightseeingSpotMarkers";
import StoryMapExploreModeButton from "../buttons/StoryMapExploreModeButton";
import {
  checkIfStorySelectedByCategory,
  checkIfStorySelectedByExperienceLevel,
  isPremiumStory,
} from "../../helpers/story-helpers";
import useIonVisible from "../../hooks/useIonVisible";
import { useExploreStoriesByCoordinatesQuery } from "../../graphql/dato/__generated__/dato-graphql.generated";
import { useLocale } from "../../contexts/LocaleContext";
import { Category, SightseeingSpot, Story } from "../../interfaces/Interfaces";
import StoryMapSightseeingSpotModal from "../modals/StoryMapSightseeingSpotModal";
import useStoryPlayer from "../../hooks/useStoryPlayer";
import useRoutes from "../../hooks/useRoutes";
import useAnalyticsStore from "../../stores/useAnalyticsStore";
import StoryMapStoryDetailModal from "../modals/StoryMapStoryDetailModal";
import useCategories from "../../hooks/useCategories";
import { isIosVersion } from "../../helpers/device-helpers";
import { useCity } from "../../contexts/CityContext";
import useKeyboard from "../../hooks/useKeyboard";
import GeolocateControl from "./GeolocateControl";
import { useTransaction } from "../../contexts/TransactionContext";
import useAuthStore from "../../stores/useAuthStore";
import MapSearchForSelectedPlaceButton from "../buttons/MapSearchForSelectedPlaceButton";
import { getDistanceBetweenCoordinates } from "../../helpers/turf-helpers";
import CircleLayer from "./CircleLayer";
import useToast from "../../hooks/useToast";
import useOnboardingHint from "../../hooks/useOnboardingHint";
import SearchInSelectedPlaceButtonHint from "../onboardingHints/SearchInSelectedPlaceButtonHint";
import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";
import CityMarkers from "./CityMarkers";
import useCitiesWithStoriesCount from "../../hooks/useCitiesWithStoriesCount";
import useExperienceLevels from "../../hooks/useExperienceLevels";
import StoryMarkers from "./StoryMarkers";
import { setMapLanguage } from "../../helpers/map-helpers";
import useSightseeingSpotsByCoordinates from "../../hooks/useSightseeingSpotsByCoordinates";

const initialViewState = {
  latitude: 52.5142,
  longitude: 13.39,
  zoom: 13,
};

const queryRadius = 50000; // in meters

const StoryMap: React.FC<{
  renderAdditionalButtonInCitySearch?: (button: JSX.Element) => void;
}> = ({ renderAdditionalButtonInCitySearch }) => {
  const { locale, queryLocale } = useLocale();
  const { isVisible } = useIonVisible();
  const { navigateToStory } = useStoryPlayer();
  const router = useIonRouter();
  const { currentPath, loginPath, premiumAccessPurchasingPath } = useRoutes();
  const { categoriesInQueryLocale, isAllCategoriesReceivedInQueryLocale } =
    useCategories(isVisible);
  const {
    experienceLevelsInQueryLocale,
    isAllExperienceLevelsReceivedInQueryLocale,
  } = useExperienceLevels(isVisible);
  const { currentCity, setPlaceNameData } = useCity();
  const { isKeyboardOpen, wasKeyboardOpen } = useKeyboard();
  const { hasPremiumAccess, activeTransactionsLoading } = useTransaction();
  const { presentToast } = useToast();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const searchInPlaceHintTargetRef = useRef<HTMLIonButtonElement>(null);
  const [
    isSearchInPlaceFunctionalityVisible,
    setIsSearchInPlaceFunctionalityVisible,
  ] = useState(false);
  useOnboardingHint({
    component: SearchInSelectedPlaceButtonHint,
    hintTargetRef: searchInPlaceHintTargetRef,
    hintName: "storyMapSearchInSelectedPlaceButton",
    isVisible: isSearchInPlaceFunctionalityVisible,
  });

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);

  const [storiesInQueryLocale, setStoriesInQueryLocale] = useState<{
    [key: string]: Story[];
  }>({});

  const mapRef = useRef<MapRef>(null);

  const [viewState, setViewState] = useState(initialViewState);
  const [debouncedViewState, setDebouncedViewState] = useDebouncedState(
    initialViewState,
    100,
    0
  );
  const [queryViewState, setQueryViewState] = useState<
    Pick<ViewState, "latitude" | "longitude" | "zoom"> & {
      zoomingRadius?: number;
    }
  >(initialViewState);
  const { sightseeingSpotsInQueryLocale, resetSightseeingSpots } =
    useSightseeingSpotsByCoordinates(isVisible, queryRadius, queryViewState);
  const [selectedStory, setSelectedStory] = useState<Story | null>(null);
  const [selectedCluster, setSelectedCluster] = useState<Story[]>([]);
  const [selectedSightseeingSpot, setSelectedSightseeingSpot] =
    useState<SightseeingSpot | null>();

  const [selectedCategories, setSelectedCategories] = useState<{
    [key: string]: string[];
  }>({});
  const [selectedExperienceLevels, setSelectedExperienceLevels] = useState<{
    [key: string]: string[];
  }>({});
  const [
    selectedIsIncludingViewedStories,
    setSelectedIsIncludingViewedStories,
  ] = useState<{
    [key: string]: boolean;
  }>({});

  const [mapBounds, setMapBounds] = useThrottledState<
    LngLatBounds | null | undefined
  >(null, 1000);

  const { citiesInQueryLocale } = useCitiesWithStoriesCount(!isVisible);

  const [highlightedStory, setHighlightedStory] =
    useDebouncedState<Story | null>(null, 100, 0);

  // Query for stories when rending the component
  const storiesPageSize = 100;
  const [storiesPageNumber, setStoriesPageNumber] = useState(0);

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      latitude: queryViewState.latitude,
      longitude: queryViewState.longitude,
      radius: queryRadius, // radius in meters
      first: storiesPageSize,
      skip: storiesPageNumber * storiesPageSize,
    };
  }, [queryLocale, queryViewState, storiesPageNumber]);

  const [result] = useExploreStoriesByCoordinatesQuery({
    variables: queryVariables(),
    pause: !isVisible,
  });
  const { data: storiesData } = result;

  const [
    presentStoryMapSightseeingSpotModal,
    dismissStoryMapSightseeingSpotModal,
  ] = useIonModal(StoryMapSightseeingSpotModal, {
    cluster: selectedCluster,
    // pass selected sightseeing spot or find the sightseeing spot related to the selected cluster
    sightSeeingSpot:
      selectedSightseeingSpot ||
      find(
        sightseeingSpotsInQueryLocale?.[locale],
        (sightSeeingSpot) =>
          sightSeeingSpot?.location?.latitude ===
            get(head(selectedCluster), "location.latitude") &&
          sightSeeingSpot?.location?.longitude ===
            get(head(selectedCluster), "location.longitude")
      ),
    currentCity,
    categories: categoriesInQueryLocale?.[locale],
    experienceLevels: experienceLevelsInQueryLocale?.[locale],
    navigateToStory: async ({ story }: { story: Story }) => {
      if (activeTransactionsLoading || !story) return;

      await dismissStoryMapSightseeingSpotModal();

      if (hasPremiumAccess || !isPremiumStory(story)) {
        resetSelectedClusterAndSightseeingSpot();
        navigateToStory({ story });
      } else {
        router?.push(
          isAuthenticated
            ? premiumAccessPurchasingPath(currentPath())
            : loginPath(premiumAccessPurchasingPath(currentPath()))
        );
      }
    },
    navigateToLoginPage: () => {
      dismissStoryMapSightseeingSpotModal();
      router.push(loginPath(currentPath()));
    },
    onDismiss: async () => {
      await dismissStoryMapSightseeingSpotModal();
      resetSelectedClusterAndSightseeingSpot();
    },
  });

  const [presentStoryMapStoryDetailModal, dismissStoryMapStoryDetailModal] =
    useIonModal(StoryMapStoryDetailModal, {
      story: selectedStory,
      isViewed: viewedStoryIds.includes(selectedStory?.id),
      navigateToStory: async ({ story }: { story: Story }) => {
        if (activeTransactionsLoading || !story) return;

        await dismissStoryMapStoryDetailModal();

        if (hasPremiumAccess || !isPremiumStory(story)) {
          setSelectedStory(null);
          navigateToStory({ story });
        } else {
          router?.push(
            isAuthenticated
              ? premiumAccessPurchasingPath(currentPath())
              : loginPath(premiumAccessPurchasingPath(currentPath()))
          );
        }
      },
      onDismiss: async () => {
        await dismissStoryMapStoryDetailModal();
        setSelectedStory(null);
      },
    });

  useEffect(
    () => {
      setDebouncedViewState(viewState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewState]
  );

  // reset stories and sightseeing spots if the current city changes
  useEffect(
    () => {
      if (currentCity) resetStoriesAndSightseeingSpots();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCity]
  );

  useEffect(() => {
    setStoriesPageNumber(0);
  }, [queryLocale]);

  useEffect(
    () => {
      if (wasKeyboardOpen && !isKeyboardOpen) resizeMap();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isKeyboardOpen]
  );

  useEffect(() => {
    if (currentCity?.location?.latitude && currentCity?.location?.longitude) {
      const cityCoordinates = {
        zoom: initialViewState.zoom,
        latitude: currentCity.location.latitude,
        longitude: currentCity.location.longitude,
        zoomingRadius: 10000,
      };
      setQueryViewState(cityCoordinates);
    }
  }, [currentCity]);

  useDebouncedEffect(
    () => {
      if (mapRef?.current && queryViewState.zoomingRadius) {
        flyToByRadius(queryViewState.zoomingRadius, [
          queryViewState.longitude,
          queryViewState.latitude,
        ]);
      }
    },
    [mapRef, queryViewState],
    100
  );

  useEffect(
    () => {
      if (selectedStory) {
        presentStoryMapStoryDetailModal({
          // set animated false for ios 17,because animation breaks the popup
          // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
          animated: !isIosVersion(17),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStory]
  );

  useEffect(
    () => {
      if (selectedCluster?.length || selectedSightseeingSpot) {
        presentStoryMapSightseeingSpotModal({
          // set animated false for ios 17,because animation breaks the popup
          // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
          animated: !isIosVersion(17),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCluster, selectedSightseeingSpot]
  );

  // Set filter for displaying viewed stories
  useEffect(
    () => {
      const getSelectedIsIncludingViewedStoriesFromStorageAndSet = async () => {
        const previouslySelectedIsIncludingViewedStories =
          await getItemFromStorage("selectedIsIncludingViewedStories");
        if (!isBoolean(previouslySelectedIsIncludingViewedStories?.[locale])) {
          setSelectedIsIncludingViewedStories({
            ...(previouslySelectedIsIncludingViewedStories || {}),
            [locale]: true,
          });
        } else {
          setSelectedIsIncludingViewedStories(
            previouslySelectedIsIncludingViewedStories
          );
        }
      };
      getSelectedIsIncludingViewedStoriesFromStorageAndSet();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  // Set categories when data is available
  useEffect(
    () => {
      const getSelectedCategoriesFromStorageAndSet = async () => {
        const previouslySelectedCategories = await getItemFromStorage(
          "selectedCategories"
        );

        if (!previouslySelectedCategories?.[locale]) {
          // set all categories as chosen for the current locale for the first time
          setSelectedCategories({
            ...(previouslySelectedCategories || {}),
            [locale]: map(
              categoriesInQueryLocale[locale],
              (category) => category.id
            ),
          });
        } else {
          // set previously selected categories from storage
          setSelectedCategories(previouslySelectedCategories);
        }
      };

      if (isAllCategoriesReceivedInQueryLocale[locale]) {
        getSelectedCategoriesFromStorageAndSet();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAllCategoriesReceivedInQueryLocale]
  );

  // Set experience levels when data is available
  useEffect(
    () => {
      if (isAllExperienceLevelsReceivedInQueryLocale[queryLocale]) {
        const getSelectedCategoriesFromStorageAndSet = async () => {
          const previouslySelectedExperienceLevels = await getItemFromStorage(
            "selectedExperienceLevels"
          );

          if (!previouslySelectedExperienceLevels?.[locale]) {
            // set all experience levels as chosen for the current locale for the first time
            setSelectedExperienceLevels({
              ...(previouslySelectedExperienceLevels || {}),
              [locale]: map(
                experienceLevelsInQueryLocale[locale],
                (experienceLevel) => experienceLevel.id
              ),
            });
          } else {
            // set previously selected categories from storage
            setSelectedExperienceLevels(previouslySelectedExperienceLevels);
          }
        };

        getSelectedCategoriesFromStorageAndSet();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isAllExperienceLevelsReceivedInQueryLocale,
      experienceLevelsInQueryLocale,
      queryLocale,
    ]
  );

  // Reset the selected cluster if language changes
  useEffect(
    () => {
      resetSelectedClusterAndSightseeingSpot();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  // Calculate the bounds of the map
  useEffect(
    () => {
      calculateMapBounds();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapRef, debouncedViewState]
  );

  // Set stories when data is available
  useEffect(
    () => {
      if (storiesData?.stories) {
        // Add stories to stories list
        const returnedStories = storiesData.stories as Story[];
        const newStoriesInQueryLocale = uniqBy(
          [...(storiesInQueryLocale?.[locale] || []), ...returnedStories],
          (story) => story.id
        );
        setStoriesInQueryLocale({
          ...storiesInQueryLocale,
          [locale]: newStoriesInQueryLocale,
        });

        // Show a notification that there are no stories in the selected place
        if (storiesPageNumber === 0 && !returnedStories?.length) {
          presentToast(
            "storyMap.noStoriesAvailable",
            "primary",
            5000,
            "bottom"
          );
        }

        // Fetch more stories if available
        if (returnedStories.length === storiesPageSize) {
          // By setting the page size another GraphQL query for the next page gets executed
          setStoriesPageNumber(storiesPageNumber + 1);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storiesData]
  );

  // Hint for MapSearchForSelectedPlaceButton can be displayed wrongly if use debouncedViewState.
  // For example: Install app -> select 'Ettlingen' on the home page -> go to the map -> hint is displayed on the top of the page and the button is hidden
  useEffect(() => {
    const isSearchInPlaceFunctionalityVisible =
      (getDistanceBetweenCoordinates(queryViewState, viewState) || 0) >
      queryRadius;
    setIsSearchInPlaceFunctionalityVisible(isSearchInPlaceFunctionalityVisible);
  }, [queryViewState, viewState]);

  // Ensure map is properly sized depending on screen height
  // and open StoryMapSightseeingSpotModal if cluster is set
  useIonViewDidEnter(() => {
    resizeMap();

    if (selectedCluster?.length || selectedSightseeingSpot) {
      presentStoryMapSightseeingSpotModal({
        // set animated false for ios 17,because animation breaks the popup
        // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
        animated: !isIosVersion(17),
      });
    }
  }, [mapRef, selectedCluster, selectedSightseeingSpot]);

  const filteredStories = useMemo(
    () =>
      (storiesInQueryLocale?.[locale] || []).filter((story) => {
        const storyLocation = story.location;

        if (!storyLocation) return false;
        if (!mapBounds) return false;

        const storyBounds = [
          storyLocation.longitude,
          storyLocation.latitude,
        ] as LngLatLike;

        return (
          mapBounds.contains(storyBounds) &&
          checkIfStorySelectedByCategory(
            story,
            selectedCategories[locale],
            categoriesInQueryLocale[locale]
          ) &&
          checkIfStorySelectedByExperienceLevel(
            story,
            selectedExperienceLevels[locale],
            experienceLevelsInQueryLocale[locale]
          ) &&
          (selectedIsIncludingViewedStories[locale] ||
            !viewedStoryIds.includes(story.id))
        );
      }),
    [
      mapBounds,
      storiesInQueryLocale,
      locale,
      selectedCategories,
      categoriesInQueryLocale,
      selectedExperienceLevels,
      experienceLevelsInQueryLocale,
      selectedIsIncludingViewedStories,
      viewedStoryIds,
    ]
  );

  // Group stories by coordinates
  const groupedByLocation = useMemo(
    () =>
      values(
        groupBy(
          filteredStories,
          ({ location }: Story) =>
            `${location?.latitude} ${location?.longitude}`
        )
      ),
    [filteredStories]
  );

  // Split stories with unique coordinates and with duplicated coordinates
  // and add stories that belong to sightseeing spot to cluster
  const [clusters, groupsWithUniqCoordinates] = useMemo(
    () =>
      partition(groupedByLocation, (group) => {
        return (
          group.length > 1 ||
          !!find(
            sightseeingSpotsInQueryLocale[locale],
            (sightseeingSpot) =>
              get(sightseeingSpot, "location.latitude") ===
                get(head(group), "location.latitude") &&
              get(sightseeingSpot, "location.longitude") ===
                get(head(group), "location.longitude")
          )
        );
      }),
    [groupedByLocation, locale, sightseeingSpotsInQueryLocale]
  );
  const storiesWithUniqCoordinates = useMemo(
    () => flatten(groupsWithUniqCoordinates),
    [groupsWithUniqCoordinates]
  );

  const updateSelectedIsIncludingViewedStories = async (
    isIncludingViewedStories: boolean
  ) => {
    const selectedIsIncludingViewedStoriesForLocales = {
      ...selectedIsIncludingViewedStories,
      [locale]: isIncludingViewedStories,
    };
    setSelectedIsIncludingViewedStories(
      selectedIsIncludingViewedStoriesForLocales
    );
    await setItemToStorage(
      "selectedIsIncludingViewedStories",
      selectedIsIncludingViewedStoriesForLocales
    );
  };

  const resetSelectedClusterAndSightseeingSpot = () => {
    if (selectedCluster?.length) setSelectedCluster([]);
    if (selectedSightseeingSpot) setSelectedSightseeingSpot(null);
  };

  const resizeMap = () => {
    mapRef.current?.resize();
    calculateMapBounds();
  };

  const calculateMapBounds = () => {
    const currentMapBounds = mapRef?.current?.getBounds();
    setMapBounds(currentMapBounds);
  };

  const updateSelectedCategories = async (categories: string[]) => {
    const selectedCategoriesForLocales = {
      ...selectedCategories,
      [locale]: categories,
    };
    setSelectedCategories(selectedCategoriesForLocales);
    await setItemToStorage("selectedCategories", selectedCategoriesForLocales);
  };

  const updateSelectedExperienceLevels = async (experienceLevels: string[]) => {
    const selectedExperienceLevelsForLocales = {
      ...selectedExperienceLevels,
      [locale]: experienceLevels,
    };
    setSelectedExperienceLevels(selectedExperienceLevelsForLocales);
    await setItemToStorage(
      "selectedExperienceLevels",
      selectedExperienceLevelsForLocales
    );
  };

  const selectStory = (story: Story | null) => {
    setSelectedStory(story);
    setHighlightedStory(story);
  };

  const flyToByRadius = (radius: number, center: [number, number]) => {
    const rangeCircle = circle(center, radius, { units: "meters" });
    const [minX, minY, maxX, maxY] = bbox(rangeCircle);
    mapRef?.current?.fitBounds([
      [minX, minY],
      [maxX, maxY],
    ]);
  };

  const resetStoriesAndSightseeingSpots = () => {
    setStoriesInQueryLocale({});
    resetSightseeingSpots();
    setStoriesPageNumber(0);
  };

  useCustomCompareEffect(
    () => {
      isVisible &&
        renderAdditionalButtonInCitySearch &&
        renderAdditionalButtonInCitySearch(
          <StoryMapFilter
            stories={storiesInQueryLocale[locale] || []}
            categories={categoriesInQueryLocale?.[locale] as Category[]}
            previouslySelectedCategoryIds={selectedCategories?.[locale]}
            setSelectedCategories={updateSelectedCategories}
            experienceLevels={experienceLevelsInQueryLocale?.[locale]}
            previouslySelectedExperienceLevelIds={
              selectedExperienceLevels?.[locale]
            }
            setSelectedExperienceLevels={updateSelectedExperienceLevels}
            previouslySelectedIsIncludingViewedStories={
              selectedIsIncludingViewedStories?.[locale]
            }
            setSelectedIsIncludingViewedStories={
              updateSelectedIsIncludingViewedStories
            }
          />
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      storiesInQueryLocale?.[locale],
      categoriesInQueryLocale?.[locale],
      selectedCategories?.[locale],
      experienceLevelsInQueryLocale?.[locale],
      selectedExperienceLevels?.[locale],
      selectedIsIncludingViewedStories?.[locale],
      isVisible,
    ],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  return (
    <div className="h-full w-full">
      <div className="relative h-full w-full">
        <Map
          ref={mapRef}
          {...viewState}
          onLoad={(e) => {
            setMapLanguage(e, locale);
            calculateMapBounds();
          }}
          onMove={(evt) => setViewState(evt.viewState)}
          attributionControl={false}
          reuseMaps={true}
          dragRotate={false}
          style={{
            height: "100%",
            width: "100%",
          }}
          mapStyle="mapbox://styles/thomas-guidable/ckwju2mpv96fa14mplcpx79ld"
        >
          {isVisible && (
            <GeolocateControl
              mapRef={mapRef}
              updateCityByUserLocationOnLocateButtonClicking={true}
            />
          )}

          {isSearchInPlaceFunctionalityVisible && (
            <MapSearchForSelectedPlaceButton
              buttonRef={searchInPlaceHintTargetRef}
              onClick={() => {
                if (mixpanelEnabled) {
                  mixpanel.track(
                    MixpanelEvents.STORY_MAP_SEARCH_IN_SELECTED_PLACE_BUTTON
                  );
                }

                setPlaceNameData({ placeName: "", resetAllData: true });
                resetStoriesAndSightseeingSpots();
                setQueryViewState({ ...viewState, zoomingRadius: queryRadius });
              }}
            />
          )}

          <CircleLayer
            viewState={queryViewState}
            hideCircleIfMapIsFullyInside={true}
            mapBounds={mapBounds}
            radius={queryRadius}
            type="fill"
            paint={{
              "fill-color": "#e38873",
              "fill-opacity": 0.1,
            }}
          />

          {isSearchInPlaceFunctionalityVisible && (
            <CircleLayer
              viewState={viewState}
              hideCircleIfMapIsFullyInside={false}
              radius={queryRadius}
              type="line"
              paint={{
                "line-color": "#e38873",
                "line-opacity": 0.3,
                "line-width": 3,
              }}
            />
          )}

          {Capacitor.isNativePlatform() && !!currentCity?.id && (
            <StoryMapExploreModeButton
              selectedCategories={selectedCategories?.[locale]}
              categories={categoriesInQueryLocale?.[locale]}
              selectedExperienceLevels={selectedExperienceLevels?.[locale]}
              experienceLevels={experienceLevelsInQueryLocale?.[locale]}
              selectedIsIncludingViewedStories={
                selectedIsIncludingViewedStories?.[locale]
              }
            />
          )}

          {!!mapBounds && (
            <CityMarkers
              cities={citiesInQueryLocale[locale]}
              mapRef={mapRef}
              mapBounds={mapBounds}
              zoom={viewState.zoom}
              viewState={queryViewState}
              radius={queryRadius}
            />
          )}

          {!!mapBounds && (
            <StoryMarkers
              stories={storiesWithUniqCoordinates}
              highlightedStory={highlightedStory}
              setSelectedStory={selectStory}
              markerSize={round(Math.pow(debouncedViewState.zoom, 3) / 100)}
            />
          )}

          {!!mapBounds && (
            <ClusterMarkers
              clusters={clusters}
              sightSeeingSpots={sightseeingSpotsInQueryLocale?.[locale]}
              setCluster={(cluster) => {
                // reset selected sightseeing spot if the cluster is selected
                if (selectedSightseeingSpot) setSelectedSightseeingSpot(null);
                setSelectedCluster(cluster);
              }}
              zoom={debouncedViewState?.zoom}
            />
          )}

          {!!mapBounds && (
            <SightseeingSpotMarkers
              clusters={clusters}
              sightSeeingSpots={sightseeingSpotsInQueryLocale?.[locale]}
              setCluster={(cluster) => {
                // reset selected sightseeing spot if the cluster is selected
                if (selectedSightseeingSpot) setSelectedSightseeingSpot(null);
                setSelectedCluster(cluster);
              }}
              setSightseeingSpot={(sightseeingSpot) => {
                // reset selected cluster if the sightseeing spot is selected
                if (selectedCluster?.length) setSelectedCluster([]);
                setSelectedSightseeingSpot(sightseeingSpot);
              }}
              zoom={debouncedViewState?.zoom}
            />
          )}
        </Map>
      </div>
    </div>
  );
};

export default memo(StoryMap);
