import React from "react";
import { IonCheckbox, IonItem } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { pinSharp } from "ionicons/icons";
import { useFormContext } from "react-hook-form";

import StepHeader from "./StepHeader";
import AppCitySearch from "../../components/form/AppCitySearch";
import PlaceSearch from "./PlaceSearch";
import { useCity } from "../../contexts/CityContext";
import LocationsPreviewMap from "./LocationsPreviewMap";

const checkboxItemStyle = {
  "--background-focused": "transparent",
  "--background-hover": "transparent",
  "--background-activated": "transparent",
  "--ripple-color": "transparent",
  "--color": "#232437",
};

const CityStep: React.FC = () => {
  const { t } = useTranslation();
  const { currentCity } = useCity();
  const { watch, setValue } = useFormContext();
  const isStartAndEndPointsSelectionEnabled = watch(
    "isStartAndEndPointsSelectionEnabled"
  );
  const isEndPointSameAsStartPoint = watch("isEndPointSameAsStartPoint");

  return (
    <div>
      <StepHeader
        number={1}
        title={t("createTour.form.city.title")}
        hint={t("createTour.form.city.hint")}
      />
      <AppCitySearch />

      <IonItem lines="none" style={checkboxItemStyle}>
        <IonCheckbox
          slot="start"
          labelPlacement="end"
          mode="md"
          checked={isStartAndEndPointsSelectionEnabled}
          disabled={!currentCity}
          onClick={() =>
            setValue(
              "isStartAndEndPointsSelectionEnabled",
              !isStartAndEndPointsSelectionEnabled
            )
          }
        >
          <span className="whitespace-normal">
            {t("createTour.form.setStartAndEndPoints.label")}
          </span>
        </IonCheckbox>
      </IonItem>

      {isStartAndEndPointsSelectionEnabled && (
        <div className="py-4">
          <PlaceSearch
            placeholderTranslationKey="createTour.form.selectStartPoint.placeholder"
            icon={pinSharp}
            iconClassName="-rotate-90"
            inputClassName="mb-3"
            name="startPoint"
          />
          {!isEndPointSameAsStartPoint && (
            <PlaceSearch
              placeholderTranslationKey="createTour.form.selectEndPoint.placeholder"
              icon={pinSharp}
              iconClassName="rotate-90"
              name="endPoint"
            />
          )}
          <IonItem lines="none" style={checkboxItemStyle}>
            <IonCheckbox
              slot="start"
              labelPlacement="end"
              mode="md"
              checked={isEndPointSameAsStartPoint}
              onClick={() =>
                setValue(
                  "isEndPointSameAsStartPoint",
                  !isEndPointSameAsStartPoint
                )
              }
            >
              <span className="whitespace-normal">
                {t("createTour.form.endTourAtStartingPoint.label")}
              </span>
            </IonCheckbox>
          </IonItem>
        </div>
      )}

      <div className="h-[300px] w-full">
        <LocationsPreviewMap />
      </div>
    </div>
  );
};

export default CityStep;
