import { memo, Ref } from "react";
import { IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const MapSearchForSelectedPlaceButton: React.FC<{
  buttonRef: Ref<HTMLIonButtonElement> | undefined;
  onClick: () => void;
  className?: string;
}> = ({
  buttonRef,
  onClick,
  className = "bottom-7 left-1/2 transform -translate-x-1/2",
}) => {
  const { t } = useTranslation();

  return (
    <IonButton
      ref={buttonRef}
      size="small"
      shape="round"
      className={clsx("absolute z-50 normal-case", className)}
      onClick={onClick}
    >
      {t("storyMap.searchInSelectedPlaceButton")}
    </IonButton>
  );
};

export default memo(MapSearchForSelectedPlaceButton);
